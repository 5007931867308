import { useState } from "react";
import { headerCardList } from "../interfaces/FAQ.interface";
import { DefaultPageTemplate } from "../utils/utils.components";

/**
 * @name FAQPage
 * @description Page FAQ (si modif a faire aller dans l'interface dedier)
 * @constructor
 */
const FAQPage = () => {
  const [selectSection, setSelectSection] = useState(0);
  const [isOpen, setIsOpen] = useState(
    Array(headerCardList.length).fill(false),
  );

  return (
    <DefaultPageTemplate>
      <main id={"response"} className="px-2 md:px-[20%] py-20">
        <h1 className={"font-bold text-lg"}>MENTIONS LEGALES</h1>
        <br />
        <span className={"flex flex-col gap-5 w-full"}>
          <h2 className={"font-bold"}>
            Éditeur du site
          </h2>
          <p className={""}>
            Le Site web est édité par Sinotech, société par actions simplifiées au capital de 100 000 €, immatriculée au RCS de Nanterre sous
            le numéro 425 062 197, dont le siège social est situé au 140 b avenue Charles de Gaulle 92200 Neuilly sur Seine et dont le
            numéro de TVA intracommunautaire est FR 62 425 062 197.
            Téléphone : 01 47 47 13 85
            courrier électronique : support@flatway.fr
            Le directeur de la publication du Site web est Madame Jocelyne Pierreau
          </p>
        </span>

        <br />
        <span className={"flex flex-col gap-5 w-full"}>
          <h2 className={"font-bold"}>
            Hébergeur du site
          </h2>
          <p className={""}>
            OVH - SAS au capital de 10 059 500 €, immatriculée au RCS Lille Métropole 424 761 419 00045, dont le siège social est situé au 2
            rue Kellermann - 59100 Roubaix – France et dont le numéro de TVA intracommunautaire est FR 22 424 761 419
          </p>
        </span>

        <br />
        <h1 className={"font-bold text-lg"}>CONDITIONS GENERALES D’UTILISATION</h1>
        <br />


        <span>
          <div>
            <span className={"font-bold"}>
              1.&nbsp;
            </span>
            <span>
              Objet
            </span>
            <br />
          </div>
          <p className={""}>
            Les présentes Conditions Générales d’Utilisation (ci-après nommées « CGU ») ont pour objet de définir les conditions
            d’utilisation du site web accessible à l’adresse www.flatway.fr (ci-après, le « Site web ») et des applications mobiles associées
            Flatway et FlatwayPro sur iOS et Android (ci-après, les « Applications ») que la société Sinotech (ci-après, la « Société ») met à
            disposition des internautes (ci-après, « Utilisateurs ») ainsi que les conditions d’utilisation, par les Utilisateurs, des Services qui y
            sont proposés (ci-après, les « Services Flatway »).
          </p>
        </span>

        <br />
        <br />
        <span>
          <div>
            <span className={"font-bold"}>
              2.&nbsp;
            </span>
            <span>
              Définitions
            </span>
          </div>
          <br />
          <p className={""}>
            Dans le cadre des présentes CGU, les termes précédés d’une majuscule sont définis ci-dessous :<br /><br />
            Acheteur : désigne toute personne physique majeure titulaire d’un Compte Utilisateur personnel qui procède, depuis une
            Annonce, à l’achat d’un ou plusieurs Biens sur l’application Flatway à un Vendeur.<br /><br />
            Annonce : désigne l'ensemble des éléments et données (visuelles et textuelles) d'un bien immobilier, déposé́par un Vendeur
            sous sa responsabilité́éditoriale, en vue de vendre un bien et diffusé sur le Site et les Applications.<br /><br />
            Application : désigne l’application mobile et tablette « Flatway » disponibles sur IOS (App Store) et Android (Google Play Store)
            par téléchargement gratuit.<br /><br />
            Application Pro : désigne l’application mobile et tablette « FlatwayPro » disponibles sur IOS (App Store) et Android (Google Play
            Store) par téléchargement gratuit.<br /><br />
            Collaborateur : Utilisateur inscrit par un Partenaire via l’Application FlatwayPro, détenant un Compte Utilisateur Pro
            Contenu : tout contenu déposé sur le Site ou l'Application par une personne tierce à la Société, à destination du public et
            susceptible d'être signalé par un Utilisateur ou un Destinataire du Service. Il s'agit notamment du contenu des annonces
            immobilières, les publicités, etc.<br /><br />
            Contenu illicite : toute information qui, en soi ou par rapport à une activité, y compris la vente de produits ou la fourniture de
            services, n’est pas conforme au droit de l’Union européenne ou au droit d’un Etat membre qui est conforme au droit de l’Union
            européenne, quel que soit l’objet précis ou la nature précise de ce droit, conformément au Règlement sur les Services
            Numériques.<br /><br />
            Comptes utilisateurs : désignent les Comptes utilisateur personnels et les Comptes utilisateur Pro.<br /><br />
            Compte utilisateur personnel : désigne l’espace personnel de l’Utilisateur à usage privé, lui permettant d’utiliser les Services
            grâce à ses Identifiants.<br /><br />
            Compte utilisateur Pro : désigne l'espace accessible depuis le Site et l’Application Pro, que tout Partenaire doit se créer afin
            d’utiliser le Service Flatway, et à partir duquel il peut notamment gérer ses rendez-vous. <br /><br />

            Données à caractère personnel : désignent les informations, sous quelque forme que ce soit, se rapportant directement ou
            indirectement à une personne physique identifiée ou identifiable (nom, prénom, date de naissance, le numéro d’adhérent, le
            numéro de sécurité sociale, l’adresse IP, les données de connexion…) telles que définies par le Règlement Général sur la
            Protection des Données (RGPD).<br /><br />
            Partenaires : désignent les agences immobilières, mandataires, notaires ou syndic de copropriétés identifiés par une adresse
            postale unique, faisant partie ou non d’un groupe ayant souscrit à l’Application FlatwayPro et donc titulaire d’un Compte
            utilisateur Pro.<br /><br />
            Site ou Site web : désigne le service électronique interactif édité et exploité par la société Sinotech, accessible notamment via
            l’adresse www.flatway.fr, les Applications et toutes déclinaisons éventuelles sur différents supports.<br /><br />
            Services : désignent les services fournis par la Société sur le Site web et/ou les Applications et décrits à l'article 4 des présentes
            CGU, ainsi que leurs extensions sous forme d'alerte, et de fonctionnalités du terminal de l'Utilisateur, nécessaires à leur
            fonctionnement.<br /><br />
            Société : désigne la société Sinotech
            Utilisateur : désigne toute personne physique, qui accède au Site et/ou aux Applications et qui crée le cas échéant un Compte
            Utilisateur sur lesdits Site et/ou Applications.<br /><br />
            Vendeur : désigne tout Utilisateur, disposant d’un Compte utilisateur personnel ayant déposé́et mis en ligne une Annonce via le
            Service Flatway.<br /><br />
            Visiteur : désigne toute personne physique qui navigue sur le Site et/ou l’Application, sans créer, ou se connecter à, un Compte
            Utilisateur. <br /><br />
            Visite sans Accompagnateur : Le Vendeur confie la vente à un professionnel et choisit d’assurer lui-même les visites de son bien
            mis en vente.<br /><br />
            Visite avec Accompagnateur : Le Vendeur confie la vente à un professionnel et confie également les visites de son bien mis en
            vente au professionnel.<br /><br />
            Applications Flatway : Flatway et FlatwayPro
          </p>
        </span>

        <br />
        <span>
          <div>
            <span className={"font-bold"}>
              3.&nbsp;
            </span>
            <span>
              Application de la CGU
            </span>
            <br />
          </div>

          <div style={{ display: 'flex' }}>
            <div style={{ padding: '10px' }}>
              <p>a.</p>
            </div>
            <div style={{ padding: '10px' }}>
              <p>Les présentes CGU définissent les conditions dans lesquelles l'Utilisateur bénéficie des Services fournis par la Société via
                Site web et/ou de ses Applications</p>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <div style={{ padding: '10px' }}>
              <p>b.</p>
            </div>
            <div style={{ padding: '10px' }}>
              <p>Tous les Services fournis par la Société et mis à disposition de l'Utilisateur sont détaillés sur le Site web et/ou sur
                l'Application.</p>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <div style={{ padding: '10px' }}>
              <p>c.</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>La souscription, l'accès ou l'utilisation des Services implique l'acceptation sans restrictions ni réserves des présentes
                CGU par l'Utilisateur.</p>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <div style={{ padding: '10px' }}>
              <p>d.</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Si l’utilisateur a téléchargé depuis l'App Store d'Apple ou le Google Play Store, une ou les Applications, l'Utilisateur a été,
                en outre, invité en amont, à accepter les termes des conditions générales propres à ces deux plateformes et s'engage à
                respecter ces dernières.</p>
            </div>
          </div>
        </span>

        <br />
        <span>
          <div>
            <span className={"font-bold"}>
              4.&nbsp;
            </span>
            <span>
              Descriptions du site et des services Flatway
            </span>
            <br />
          </div>
        </span>
        <br />
        <span>
          <div>
            <span className={"font-bold"}>
              4.1.&nbsp;
            </span>
            <span>
              - Fonctionnalité du site
            </span>
            <br />
          </div>
        </span>

        <span>
          <p>Le Site flatway.fr et les Applications sont accessibles à tout Utilisateur. En l’absence de Compte Personnel ou Pro, les Utilisateurs
            peuvent depuis le Site et les Applications : </p>
        </span>

        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>Consulter toutes les Annonces diffusées sur le Site.</p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>Effectuer une estimation, partielle, d’un bien immobilier et obtenir la liste des biens vendus aux alentours</p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>Consulter les profils des Professionnels, notamment le montant des honoraires </p>
          </div>
        </div>

        <br />
        <span>
          <div>
            <span className={"font-bold"}>
              4.2.&nbsp;
            </span>
            <span>
              - Description des services
            </span>
            <br />
          </div>
        </span>
        <br />
        <p>
          Flatway offre une plateforme technologique qui permet aux Utilisateurs de vendre ou d’acheter un bien immobilier en utilisant
          les services fournis par les professionnels de l’immobilier Partenaires, permettant de bénéficier depuis les Application et le Site
          web des Services suivants :
        </p>
        <br />
        <br />
        <p className="font-bold">Pour le Vendeur et l’Acheteur :</p>

        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>i. &nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>Création d’un Compte Utilisateur personnel afin d’accéder aux offres de vente de biens immobiliers ainsi
              qu’aux estimations des biens, notamment la liste des biens vendus aux alentours.<br /><br />
              Lors de la création du Compte Utilisateur, l’Utilisateur garantit que les informations qu’il transmet, en
              particulier les Données à caractère personnel sont exactes, sincères et à jour et doit accepter les présentes
              CGU.<br /><br />
              En créant un compte personnel, l'Utilisateur dispose d'un identifiant unique et d'un mot de passe strictement
              personnels, qu'il s'engage à conserver confidentiels et à ne pas transmettre à des tiers.<br /><br />
              L'Utilisateur est seul autorisé à accéder et utiliser les Services à l'aide de son identifiant unique et de son mot
              de passe. Tout accès au compte personnel de l'Utilisateur avec son identifiant unique et son mot de passe est
              réputé de plein droit avoir été effectué par l'Utilisateur. De même que l'accès aux Services avec ces identifiant
              et mot de passe s'effectue sous la seule responsabilité de l'Utilisateur.<br /><br />
              Dès lors, en cas de perte, de vol ou tout acte frauduleux à l'égard de son identifiant unique et de son mot de
              passe, l'Utilisateur doit en informer la Société dans les plus brefs délais et justifier à cette occasion de son
              identité par tous moyens. A réception de sa notification dûment justifiée, la Société adressera à l'Utilisateur un
              nouvel identifiant et mot de passe, à charge pour lui de les conserver strictement confidentiels.<br /><br /> </p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>ii. &nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>Enregistrement d’un bien à la vente sur Flatway et choisir le partenaire ainsi que le type d’honoraires selon la
              qualité de la vente (Avec Accompagnateur ou Sans Accompagnateur).</p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>iii. &nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>Gérer son Annonce depuis son Compte Utilisateur personnel </p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>iv. &nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>Planifier des rendez-vous de visite : l’Acheteur avec des Vendeurs dans le cas d’un bien mis en vente Sans
              accompagnement, ou bien, avec des Partenaires dans le cas d’un bien mis en vente Avec accompagnement.</p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>v. &nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>Formuler une offre d’achat depuis l’espace Vendeur, sans l’intermédiaire du Partenaire, ce à quoi suit une
              négociation avec le Vendeur. </p>
          </div>
        </div>
        <br />
        <p className="font-bold">Pour le Partenaire : </p>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>i. &nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>Inscription d’un ou plusieurs collaborateurs</p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>ii. &nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>Choix du montant des honoraires, en pourcentage du prix de vente, en fonction de la qualité des visites (Visites
              sans Accompagnateur et Visite avec Accompagnateur)</p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>iii. &nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>Gérer son planning de rendez-vous de visite depuis son Compte Utilisateur Pro</p>
          </div>
        </div>


        <span>
          <div>
            <span className={"font-bold"}>
              5.&nbsp;
            </span>
            <span>
              Accès aux services et disponibilité
            </span>
            <br />
          </div>

          <span className="font-bold">a.</span>
          <span>Accès</span>
          <p>Les Services sont librement accessibles exclusivement en ligne sur le Site et/ou sur les Applications de Flatway. </p>
          <br /><br />
          <span className="font-bold">b.</span>
          <span>Disponibilité</span>
          <p>L’accès au Site et/ou aux Applications Flatway et aux Services qui y sont proposés est exclusivement réservé aux Utilisateurs
            majeurs. Dès lors, Flatway se réserve le droit (i) de procéder à tout contrôle concernant l’âge de la personne concernée et (ii) le
            cas échéant, de supprimer immédiatement tout Compte Utilisateur.<br /><br />
            Le téléchargement des Applications Flatway iOS/Android nécessite l’acception des CGU du magasin d’applications respectif. La
            société ne peut être tenu responsable d’éventuels dysfonctionnements de téléchargements sur l’App Store d’Apple ou du Play
            Store de Google.<br /><br />
            L’Utilisateur reconnait disposer de la compétence et des moyens nécessaires pour accéder et utiliser le Site et/ou les
            Applications Flatway, avoir vérifié que la configuration informatique utilisée par lui ne contient aucun virus et qu’elle est en bon
            état de fonctionnement.<br /><br />
            La Société se réserve la possibilité de modifier, interrompre, à tout moment, temporairement ou de manière permanente tout
            ou partie du Site et/ou des Applications Flatway sans information préalable des Utilisateurs et sans droit à indemnités. Flat La
            Société ne peut pas être tenue responsable des conséquences résultant de telles interruptions ou modifications.<br /><br />
            Plus généralement, La Société se réserve le droit d’apporter toutes modifications de quelque nature qu’elles soient au contenu
            du Site et/ou aux Applications.<br /><br /></p>
        </span>


        <span>
          <div>
            <span className={"font-bold"}>
              6.&nbsp;
            </span>
            <span>
              Utilisation, garantie et responsabilité
            </span>
            <br />
          </div>
          <div>
            <span className={"font-bold"}>
              a.&nbsp;
            </span>
            <span>
              Garantie de l’Utilisateur
            </span>
            <br />
          </div>
          <br />

          <p>En accédant au Site web et/ou aux Applications de Flatway, l'Utilisateur déclare, garantit et s'engage à accéder et utiliser le Site
            web et/ou les Applications Flatway et les Services en toute bonne foi, de manière raisonnable, et conformément aux termes des
            présentes CGU.<br /><br />
            L’Utilisateur s’engage à ne pas utiliser le Site et/ou les Applications et/ ou les services : </p>
          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>à des fins illicites, contraires à l’ordre public ou aux bonnes mœurs, et/ou dans le but de causer un préjudice à la
                réputation et l'image de la Société ou plus généralement à porter atteinte aux droits, notamment de propriété
                intellectuelle, de la Société et/ou de tiers ; en violation des dispositions des lois ou règlements applicables ou des droits
                d’un tiers, à des fins pouvant causer des pertes ou dommages quels qu’ils soient,</p>
            </div>
          </div>
        </span>
        <br />
        <p>En accédant au Site web et/ou aux Applications de la Société, l'Utilisateur affirme, assure et garantit : </p>
        <br />
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>Toute information que l’Utilisateur communique est vraie, exacte, à jour et qui ne peuvent en aucun cas induire en
              erreur la Société ou les tiers. Dans ce cadre, il s'engage à actualiser les informations fournies initialement en cas de
              changement</p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>Que le contenu qu'il soumette est légal et en aucun cas diffamatoire ou attentatoire à l'ordre public, ainsi qu'aux
              intérêts et à l'image de la Société ou de tout tiers. </p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>Il s'engage également à ne pas utiliser de fausse identité ni à usurper l'identité d'une autre personne physique ou
              morale</p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>Le contenu ne doit pas contenir de contenus nuisibles pour les systèmes informatiques de la Société ou de tout tiers,
              tels que des virus, des vers informatiques, des chevaux de Troie, etc.</p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>Il ne doit en aucun cas porter atteinte aux droits et intérêts commerciaux, financiers ou moraux de la Société ou de tout
              autre Utilisateur.</p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>S’abstenir d'utiliser tout dispositif ou logiciel autre que ceux fournis par la Société dans le but i) de perturber ou de
              tenter de perturber le bon fonctionnement du Site web et/ou des Applications ainsi que des Services qu'ils proposent,
              ii) d'extraire, de modifier, de consulter, même temporairement ou en mémoire tampon, ou encore pour une utilisation
              individuelle, tout ou partie du Site web et/ou des Applications. </p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>ne pas accéder et/ou utiliser le Site web et/ou les Applications et/ou les Services fournis par la Société sur ledit Site web
              ou Applications à des fins illicites et/ou dans le but de causer un préjudice à la réputation et l'image de la Société ou
              plus généralement à porter atteinte aux droits, notamment de propriété intellectuelle, de la Société et/ou de tiers ;
            </p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>ne pas commercialiser directement ou indirectement les Services et/ou l'accès aux Services et/ou l'accès au Site web
              et/ou aux Applications ;</p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>ne pas réutiliser tout ou partie du Site web et/ou de l'Application et des Services qu'il contient, en particulier à des fins
              commerciales et/ou collectives et/ou à des fins personnelles sous une forme et/ou un média non autorisé par la Société
              ;</p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>ne pas exploiter les Services fournis par la Société ou les données auxquelles il pourrait avoir accès via lesdits Services
              et/ou le Site web et/ou les Applications à des fins directement ou indirectement commerciales et/ou à des fins
              personnelles sous une forme et/ou un média non autorisé(s) par la Société ; </p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>ne pas reproduire, représenter tout ou partie du Site web et/ou des Applications à des fins privées au-delà des
              exceptions légales prévues, en particulier par le Code de la propriété intellectuelle, ou en vue d'une commercialisation
              directe ou indirecte notamment auprès de tiers ; </p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>ne pas limiter l'accès et l'utilisation au Site web et/ou aux Applications et/ou des Services ;</p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>- ne modifier, y compris en mémoire tampon ou temporaire, aucune mention ou élément des Services et/ou du contenu
              du Site web et/ou de l'Application ; </p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>ne pas contrevenir aux dispositions des articles 323-1 à 323-7 du Code Pénal réprimant les pratiques dites de « hacking
              » ; </p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>ne pas utiliser et/ou exploiter les coordonnées électroniques et/ou postales des autres Utilisateurs du Site web et/ou
              des Application en vue de l'envoi en masse de courrier électronique de sollicitation et de se livrer à une pratique de «
              spamming »</p>
          </div>
        </div>

        <br /><br />
        <p>
          En cas de violation de l'une quelconque de ces obligations, et ce, sans que cette énumération soit exhaustive, l'Utilisateur admet
          et consent à ce que la Société se réserve le droit de lui refuser, de manière unilatérale et sans notification préalable, l'accès à
          tout ou partie du Site web et/ou des Applications, de supprimer sans délais les contenus concernés et de résilier les Comptes
          Utilisateurs qui auraient enfreints les obligations des présentes Conditions générales d’utilisations, nonobstant l’indemnisation
          de la Société de l’ensemble des préjudices subis.
        </p>

        <br /><br />
        <span>
          <div>
            <span className={"font-bold"}>
              7.&nbsp;
            </span>
            <span>
              Limitation de responsabilité
            </span>
            <br />
          </div>
        </span>
        <br />
        <p>
          Les informations données sur le Site n’ont pas un caractère contractuel. Les visuels liés aux annonces sont publiés sous la
          responsabilité des Vendeurs.        <br /><br />

          L’Utilisateur est seul responsable de l’utilisation qu’il fait du Site web et des Services auxquels il accède depuis le Site web ainsi
          que de l’utilisation des Applications et des Services auxquels il accède depuis les Applications. Par conséquent, la Société ne
          pourra en aucun cas être tenue responsable dans le cadre d’une procédure introduite à l’encontre de l’Utilisateur qui se serait
          rendu coupable d’une utilisation non-conforme du Site web et/ou Applications et/ou des Services qu’il procure.        <br /><br />

          L’Utilisateur reconnaît et accepte à cet égard qu’il fera son affaire personnelle de toute réclamation ou procédure formée contre
          La Société, du fait de l’utilisation non-conforme par lui des Services et/ou des Applications et/ou du Site web.        <br /><br />

          En toute hypothèse, la Société ne saurait être responsable :        <br />

        </p>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>en cas d’indisponibilité des Services pour des raisons telles que la défaillance du réseau public d’électricité, la
              défaillance des réseaux câblés de télécommunications, la perte de connectivité au réseau Internet due aux opérateurs
              publics ou privés, notamment de l’Utilisateur, dont les causes proviennent notamment de grèves, de tempêtes, de
              tremblements de terre ou de toute autre cause ayant les caractéristiques de la force majeure ;</p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>en cas d’utilisation des Services par un Utilisateur dans des conditions non-conformes aux termes des présentes
              Conditions Générales d’Utilisation ;</p>
          </div>
        </div>
        <div style={{ display: 'flex', marginLeft: '30px' }}>
          <div style={{ padding: '10px' }}>
            <p>-&nbsp;&nbsp;&nbsp;</p>
          </div>
          <div style={{ flex: '1', padding: '10px' }}>
            <p>dans les limites de la législation en vigueur, pour tout dommage indirect et ce y compris notamment les pertes de
              profit, de données ou tout autre perte de biens incorporels, et ce même si la Société a été informée de la potentialité
              de tels dommages, pouvant survenir (i) de l’utilisation ou de l’impossibilité d’utiliser les Services (ii) suite à l’accès
              auxdits Services par un Utilisateur non autorisé. </p>
          </div>
        </div>
        <p>Les taux de transfert et de téléchargement à partir du Site ne sont pas garantis. la Société n’est pas considérée comme
          responsable de l’utilisation frauduleuse par un tiers de l’e-mail, du mot de passe ou des autres données de l’Utilisateur.</p>
        <br />
        <span>
          <div>
            <span className={"font-bold"}>
              8.&nbsp;
            </span>
            <span>
              Force majeure
            </span>
            <br />
          </div>
          <p>
            La responsabilité de la Société ne pourra pas être recherchée si l'exécution de l'une de ses obligations est empêchée ou retardée
            en raison d'un cas de force majeure tel que défini par l'article 1218 du Code civil tel que modifié par l'ordonnance n° 2016-131
            du 10 février 2016 et interprété par la jurisprudence des Tribunaux français, et notamment, sans s'y limiter, les catastrophes
            naturelles, incendies, dysfonctionnement ou interruption du réseau de télécommunications ou du réseau électrique.
          </p>
        </span>


        <br />
        <span>
          <div>
            <span className={"font-bold"}>
              9.&nbsp;
            </span>
            <span>
              Propriété intellectuelle
            </span>
            <br />
          </div>
          <br />
          <div>
            <span className={"font-bold"}>
              a.&nbsp;
            </span>
            <span>
              Droits d’auteur sur le Site web ou sur les Applications
            </span>
            <br />
          </div>
          <p>
            La Société est le titulaire ou le concessionnaire des droits de propriété intellectuelle tant de la structure générale du Site et/ou
            des Applications que de son contenu (textes, slogans, images, vidéos, photos et autres contenus).<br />
            Par conséquent, conformément aux dispositions du Livre 1er du Code de la propriété intellectuelle toute représentation,
            reproduction, modification, dénaturation et/ou exploitation totale ou partielle du Site et/ou des Applications et/ou de son
            contenu (quel qu’en soit l’auteur) et/ou des Services, par quelque procédé que ce soit et sur quelque support que ce soit, sans
            l’autorisation expresse et préalable de la Société est prohibée et constitue des actes de contrefaçon de droits d’auteur.<br />
            De même, toute exploitation non autorisée du Site web, et/ou des Applications et/ou de son contenu et/ou des Services engage
            la responsabilité pénale et civile de l'Utilisateur sur le fondement de la contrefaçon de droits d'auteur.<br /><br />
            En revanche, la Société n’est ni propriétaire, ni responsable, à quelque titre que ce soit et même si elle est concessionnaire des
            droits de propriété intellectuelle, de l’intégralité des autres contenus sur le Site et/ou les Applications (notamment des
            annonces, textes, slogans, graphiques, images, vidéos, photos, et autres contenus), dont la responsabilité incombe
            exclusivement aux Vendeurs et Partenaires, ou bien aux différents fournisseurs de données.<br />           </p>

          <br />
          <div>
            <span className={"font-bold"}>
              b.&nbsp;
            </span>
            <span>
              Signes distinctifs
            </span>
            <br />
          </div>
          <p>
            Les marques, logos, dénominations sociales, sigles, noms commerciaux, enseignes et/ou noms de domaine de Flatway
            mentionnés sur le Site et/ou les Applications, ainsi que des Partenaires, permettant l’accès aux Services mis à disposition par la
            Société, constituent des signes distinctifs insusceptibles d’utilisation sans l’autorisation expresse et préalable de leur titulaire.<br /><br />
            Toute représentation et/ou reproduction et/ou exploitation partielle ou totale de ces signes distinctifs est donc prohibée et
            constitutif de contrefaçon de marque, en application des dispositions du Livre 7 du Code de la propriété intellectuelle,
            d'usurpation de dénomination sociale, nom commercial et de nom de domaine engageant la responsabilité civile délictuelle de
            son auteur. <br />           </p>
          <br />
          <div>
            <span className={"font-bold"}>
              c.&nbsp;
            </span>
            <span>
              Base de Données
            </span>
            <br />
          </div>
          <p>
            L'Utilisateur reconnaît irrévocablement que le Site web, et/ou l'Application et les Services sont constitués d'une ou plusieurs
            bases de données mis à disposition par la Société en qualité de producteur desdites bases de données au sens des dispositions
            des articles L.341-1 et suivants du Code de la propriété intellectuelle.<br />
            Dès lors, conformément aux dispositions de l'article L. 342-1 du même Code, l'Utilisateur s'interdit de procéder à : <br />           </p>

          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>l'extraction par transfert permanent ou temporaire de la totalité ou d'une partie qualitativement ou quantitativement
                substantielle du contenu d'une ou plusieurs des bases des données accessibles sur le Site web et/ou les Applications
                et/ou les Services sur un autre support, par tout moyen et sous toute forme que ce soit, en ce compris à des fins
                d'utilisation ou de consultation par un média et/ou un procédé(s) non autorisé(s) par la Société ; </p>
            </div>
          </div>
          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>a réutilisation, par la mise à la disposition du public de la totalité ou d'une partie qualitativement ou quantitativement
                substantielle du contenu d'une ou plusieurs des bases des données accessibles sur le Site web et/ou les Applications
                et/ou les Services, quelle qu'en soit la forme, y compris par un lien hypertexte, un média et/ou un procédé(s) non
                autorisé(s) par la Société ;</p>
            </div>
          </div>
          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>la constitution, l'édition, la maintenance, la mise à jour, l'importation, l'exportation, la mise à disposition de tiers, à titre
                gracieux ou onéreux, et la participation aux actes précités, d'une base de données concurrente issue de tout ou partie
                d'une ou plusieurs des bases de données de la Société ;</p>
            </div>
          </div>
          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>la visualisation sur un écran par un autre procédé ou média que ceux par lesquels la Société entend divulguer le Site
                web et/ou les Applications et les Services; </p>
            </div>
          </div>
          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>d'une manière générale, toute extraction, utilisation, stockage, reproduction, représentation ou conservation, directe
                ou indirecte, partielle ou totale, y compris en mémoire tampon ou temporaire, qualitativement ou quantitativement
                substantielle du contenu d'une ou plusieurs des bases de données de la Société, commise par l'un des procédés visés cidessus est strictement prohibée, y compris par un média non autorisé par la Société.</p>
            </div>
          </div>

          <br />
          <div>
            <span className={"font-bold"}>
              d.&nbsp;
            </span>
            <span>
              Services
            </span>
            <br />
          </div>
          <p>
            L'Utilisateur reconnaît et accepte que l'accès au Site web et/ou aux Applications et aux Services mis à sa disposition par la
            Société ne saurait emporter une quelconque cession ou concession des droits de propriété intellectuelle (droits d'auteurs
            notamment) et autres droits au bénéfice de l'Utilisateur.<br /><br />
            L'accès aux Services est exclusivement limité à l'usage privé et personnel de l'Utilisateur dans les conditions et limites définies
            dans les présentes CGU et conformément aux dispositions de l'article L.122-5 2° du Code de la propriété Intellectuelle.<br /><br />
            Ainsi, l'Utilisateur reconnaît et accepte que l'usage privé et personnel qui lui est concédé par la Société, au titre de l'accès à ses
            Services, exclue notamment l'accès aux Services en vue d'une utilisation collective de son contenu, la reproduction, la
            représentation, la revente, l'échange, la location, le transfert à un tiers, la modification, l'adaptation, la correction, et ce tant à
            titre gracieux qu'onéreux, de tout ou partie du Site web et/ou de l'Application, des Services, et de leurs contenus. <br />           </p>
          <br />
          <div>
            <span className={"font-bold"}>
              e.&nbsp;
            </span>
            <span>
              Opposition au droit de fouille
            </span>
            <br />
          </div>
          <p>En application de la Directive européenne (UE) 2019/790 du 17 avril 2019 sur le droit d'auteur et les droits voisins dans le
            marché unique numérique et de l'article L122-5-3 du code de la propriété intellectuelle, la Société exerce son droit d’opposition
            à la fouille de textes et de données. <br />  <br />
            Aux fins de la présente clause, la fouille s'entend de toute technique d'extraction, de collecte et d'analyse automatisée visant à
            analyser des textes et des données sous une forme numérique afin d'en dégager des informations, (ce qui comprend, à titre non
            exhaustif, des constantes, des tendances et des corrélations) et ce par tout type de moyen (à savoir notamment via robot,
            scraping, etc.) <br />  <br />
            L'opposition s'applique à tous les contenus, données et sites Internet auxquels la Société donne accès.
            A ce titre, la Société refuse expressément, de manière générale et non exhaustive, l'utilisation de son contenu à des fins :
            <br />
          </p>
          <br />
          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>De fouille, de rassemblement ou de croisement de données ainsi que toute méthode d'extraction, </p>
            </div>
          </div>
          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>De développement et d'entraînement de systèmes d'intelligence artificielle,</p>
            </div>
          </div>
          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>De développement de tout logiciel.</p>
            </div>
          </div>
        </span>

        <span>
          <div>
            <span className={"font-bold"}>
              10.&nbsp;
            </span>
            <span>
              Données personnelles –
            </span>
            <br />
          </div>
          <div>
            <span className={"font-bold"}>
              a.&nbsp;
            </span>
            <span>
              Protection des données personnelles
            </span>
            <br />
          </div>
          <p>
            La Société, en tant que responsable de traitement, met en œuvre un traitement de données à caractère personnel ayant pour
            finalité notamment la création et la gestion du Compte Utilisateur, la mise en relation avec une ou plusieurs Partenaires
            préalablement sélectionnées en vue d’obtenir des informations complémentaires ou la prise de rendez-vous ainsi que
            l’amélioration de notre offre de service et/ ou d’autres Utilisateurs.<br /><br />
            Ces traitements sont mis en œuvre soit sur la base de votre consentement, soit sur la base de l’exécution d’un contrat auquel
            vous êtes partie, ou l’exécution de mesures précontractuelles prises à votre demande, soit sur la base de notre intérêt légitime.<br /><br />
            Les Partenaires et/ou Vendeurs susceptibles de recevoir vos données agissent en qualité de responsables de traitement
            indépendants et sont liées par une clause de confidentialité et de respect des données à caractère personnel. Le traitement des
            données reçues par ces dernières se fait ainsi sous leur propre responsabilité et conformément à leur propre politique de
            protection des données.<br /><br />
            Pour plus de détails concernant le traitement de vos données à caractère personnel, y compris les catégories de données
            collectées et les finalités de traitement, nous vous invitons à consulter notre Politique de confidentialité accessible ici.<br /><br />
            L’Utilisateur bénéficie d’un droit d’information, d’accès, de rectification, d’effacement et de portabilité de ses données ; ainsi
            que d’un droit de limitation du traitement. Toute personne concernée dispose en outre d’un droit d’opposition y compris à la
            prospection commerciale. Par ailleurs, toute personne dispose d’un droit de formuler des directives spécifiques et générales
            concernant la conservation, l’effacement et la communication de ses données post-mortem.<br /><br />
            Ils ont le droit d’introduire une réclamation auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL), autorité
            de contrôle en charge du respect des obligations en matière de données à caractère personnel. <br />
          </p>
          <br />
          <div>
            <span className={"font-bold"}>
              b.&nbsp;
            </span>
            <span>
              Cookies
            </span>
            <br />
          </div>
          <p>
            Lors de la consultation du Site web et/ou des Applications, des informations sont susceptibles d'être enregistrées dans des
            fichiers « cookies » installés dans l'ordinateur, tablette ou téléphone mobile de l'Utilisateur. Pour connaître notre politique des
            cookies, les Utilisateurs peuvent consulter la Politique de cookies.<br />
          </p>
          <br />
          <div>
            <span className={"font-bold"}>
              11.&nbsp;
            </span>
            <span>
              Droit applicable
            </span>
            <br />
          </div>
          <p>
            Les présentes Conditions Générales d’Utilisation sont soumises au droit français. Toute contestation de quelque nature qu’elle
            soit, relative à l’application des présentes Conditions Générales et à l’utilisation et à la consultation du Site doit, à défaut
            d’accord amiable entre les parties, être portée devant les Tribunaux français seuls compétents.<br />
          </p>
          <div>
            <span className={"font-bold"}>
              12.&nbsp;
            </span>
            <span>
              Résiliation
            </span>
            <br />
          </div>

          <br />
          <div>
            <span className={"font-bold"}>
              a.&nbsp;
            </span>
            A l’initiative de l’utilisateur
            <span>

            </span>
            <br />
          </div>
          <p>L’Utilisateur peut clôturer son Compte Utilisateur à tout moment.</p><br />

          <div>
            <span className={"font-bold"}>
              b.&nbsp;
            </span>
            A l’initiative de la Société
            <span>

            </span>
            <br />
          </div>
          <p>a Société se réserve le droit d’interrompre de façon temporaire les Services, et de résilier les Comptes Utilisateurs, pour des
            raisons liées à la sécurité du Site et/ou des Services, ou dans les cas prévus aux présentes, notamment en cas de non-respect par
            l’Utilisateur des présentes CGU, nonobstant toute action judiciaire que pourrait intenter la Société pour la réparation de son
            préjudice. </p><br />

          <div>
            <span className={"font-bold"}>
              c.&nbsp;
            </span>
            Conséquences
            <span>
            </span>
            <br />
          </div>
          <p>La résiliation d’un Compte Utilisateur entraîne la fin de la fourniture des Services nécessitant un Compte Utilisateur. </p><br />

          <div>
            <span className={"font-bold"}>
              13.&nbsp;
            </span>
            Dispositions diverses
            <span>
            </span>
            <br />
          </div>


          <div>
            <span className={"font-bold"}>
              a.&nbsp;
            </span>
            Titres
            <span>
            </span>
            <br />
          </div>
          <p>Les intitulés portés en tête de chaque article ne servent qu’à la commodité de la lecture et ne peuvent en aucun cas être le
            prétexte d’une quelconque interprétation ou dénaturation des clauses sur lesquelles ils portent. En cas de difficulté
            d’interprétation ou de contradiction entre le contenu d’une clause et son titre, ce dernier est déclaré inexistant.</p><br />

          <div>
            <span className={"font-bold"}>
              b.&nbsp;
            </span>
            Modifications
            <span>
            </span>
            <br />
          </div>
          <p>Les présentes CGU pouvant faire l'objet de modifications, les conditions applicables sont celles en vigueur et accessibles sur le
            Site web et/ou sur les Applications de la Société à la date d'accès au Site web et/ou aux Applications par l'Utilisateur.</p><br />

          <div>
            <span className={"font-bold"}>
              c.&nbsp;
            </span>
            Nullité
            <span>
            </span>
            <br />
          </div>
          <p>Dans l'hypothèse où une ou plusieurs stipulations des présentes CGU seraient considérées comme nulles, réputées non écrites
            ou déclarées comme telles en application d'une loi, d'un règlement ou à la suite d'une décision d'une juridiction compétente
            ayant autorité de la chose jugée en dernier ressort, les autres stipulations garderont toute leur force et leur portée et resteront
            pleinement applicable, sauf si la ou les stipulations non valides présentaient un caractère substantiel et que leur disparition
            remettait en cause l'équilibre contractuel. </p><br />

          <div>
            <span className={"font-bold"}>
              d.&nbsp;
            </span>
            Intégralité
            <span>
            </span>
            <br />
          </div>
          <p>Les présentes CGU expriment l'intégralité des obligations de la Société et de l'Utilisateur. Le fait pour l'une des parties de ne pas
            se prévaloir d'un manquement par l'autre partie, à l'une quelconque des obligations visées dans les présentes, ne saurait être
            interprété pour l'avenir comme une renonciation à l'obligation en cause.</p><br />

          <div>
            <span className={"font-bold"}>
              e.&nbsp;
            </span>
            Réclamations
            <span>
            </span>
            <br />
          </div>
          <p>Toute réclamation doit être adressée à la Société via les formulaires de contact disponibles sur le Site. </p><br />

          <div>
            <span className={"font-bold"}>
              14.&nbsp;
            </span>
            Informations générales
            <span>
            </span>
            <br />
          </div>
          <p>En plus des autres informations visées dans les présentes Conditions Générales d’Utilisation, il est indiqué que la Société ne fait
            que mettre en relation, selon les modalités décrites dans les présentes Conditions Générales d’Utilisation, des Partenaires,
            professionnels de l’immobilier (agences immobilières, notaires, syndics de copropriété ou mandataires immobiliers) et des
            particuliers en vue de l’éventuelle conclusion d’une vente de biens immobiliers anciens. La société n’intervient pas dans les
            litiges qui pourraient exister entre les particuliers et les Partenaires susvisés. </p><br />


        </span>

        <br /><br />
        <p>© septembre 2024 Sinotech SAS - 140 bis Avenue Charles de Gaulle - 92200 Neuilly sur seine - RCS : 425 062 197</p>
      </main >
    </DefaultPageTemplate >
  );
}

export default FAQPage;
