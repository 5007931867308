import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PiArrowRightLight, PiCalculatorThin } from "react-icons/pi";
import { gsap } from "gsap";
import EstimationImg from "../../assets/2 - estimation/Estimation.png";
import { RiFileList3Line } from "react-icons/ri";
import { CiCalculator2 } from "react-icons/ci";
import BiensVendus from "../../assets/2 - estimation/liste Biens vendus.png";
import PDV from "../../assets/2 - estimation/Estimation avancee.png";

const explicationCardmap = [
  {
    icon: <CiCalculator2 className="text-[25px] sm:text-[30px]" />,
    title: "Estimation",
    text: "Prix du m² à une  adresse ",
  },
  {
    icon: <RiFileList3Line className="text-[25px] sm:text-[30px]" />,
    title: "Biens vendus ",
    text: "Autour de l’adresse renseignée",
  },
  {
    icon: <PiCalculatorThin className="text-[25px] sm:text-[30px]" />,

    title: "Estimation avancée ",
    text: "de votre bien en fonction de ses caractéristiques",
  },
];

export type estimationInfo = {
  adresse_complete: string;
};

/**
 * @name Estimation
 * @description Section d'estimation de la page d'accueil
 * @constructor
 */
function Estimation() {
  const navigate = useNavigate();

  return (
    <div
      className={
        "h-auto pt-[70px] lg:h-[100vh] w-full relative overflow-hidden flex items-center justify-center"
      }
    >
      <div
        className={
          "rounded-customBlobTriangle3 z-0 w-[50%] h-[20%] bg-[#cf8282] blur-3xl absolute -bottom-56 right-1/4"
        }
      />
      <main
        className={
          "xl:w-[80vw] w-full bg-no-repeat bg-cover bg-Paris z-10 h-auto overflow-hidden rounded-xl"
        }
      >
        <div
          className={
            "lg:h-[90vh] w-full gap-4 grid lg:grid-rows-2 lg:grid-flow-col py-5 px-10 lg:px-[5vw] xl:px-[7vw] bg-lightredRgba rounded-xl"
          }
        >
          <section
            className={
              "h-auto lg:h-auto py-10 w-full bg-slate-50 rounded-xl row-span-2 px-10 overflow-hidden gap-10 flex flex-col items-center justify-evenly"
            }
          >
            <EstimationHeader />
            <span className={"h-[65vh] flex items-center"}>
              <img
                src={EstimationImg}
                className={"object-scale-down h-full float-end drop-shadow-xl"}
                alt={"estimation"}
              />
            </span>
          </section>
          <section
            className={
              "h-full w-full bg-slate-50 rounded-xl 2xl:px-10 py-5 overflow-y-hidden gap-5 flex flex-col items-center justify-center"
            }
          >
            <p className={"font-sem7 text-[#737373] flex items-center h-[20%]"}>
              Estimation avancée
            </p>
            <span
              className={
                "h-[300px] w-[250px] flex md:items-center overflow-y-hidden"
              }
            >
              <img
                src={PDV}
                className={"object-cover w-full drop-shadow-xl"}
                alt={"choisir le type d'honoraires"}
              />
            </span>
          </section>
          <section
            className={`h-auto w-full transition-all  duration-300 bg-pdv bg-no-repeat bg-left bg-cover
          rounded-xl`}
          >
            <div
              className={`h-full transition-all duration-300
                              bg-slate-50
                        px-10 py-5 gap-10 flex flex-col rounded-xl items-center justify-center`}
            >
              <div
                className={
                  "flex flex-col gap-3 w-full lg:h-[70%] justify-center"
                }
              >
                {explicationCardmap.map(({ title, text }, index) => {
                  return (
                    <div key={index} className={`flex items-center`}>
                      <span className={"w-[10%]"}>
                        <PiArrowRightLight className={"text-xl"} />
                      </span>{" "}
                      <p>
                        <span className={"font-bold"}>{title}</span>
                        <br />
                        <span className={"text-xs"}>{text}</span>
                      </p>
                    </div>
                  );
                })}
              </div>
              <span className={"h-[30%]"}>
                <button
                  onClick={() => {
                    window.scroll(0, 0);
                    navigate("/estimation");
                  }}
                  className={
                    "font-bold text-white w-fit h-fit bg-dark-red px-4 py-2 rounded-lg"
                  }
                >
                  Estimer
                </button>
              </span>
            </div>
          </section>
          <section
            className={
              "h-full w-full py-10 row-span-2 bg-slate-50 rounded-xl px-10 overflow-y-hidden gap-5 flex flex-col items-center justify-center"
            }
          >
            <p
              className={
                "font-semibold text-[#737373] flex justify-center items-center h-[20%] lg:h-[15%]"
              }
            >
              Biens vendus
            </p>
            <span
              className={
                "h-[65vh] flex items-center justify-center overflow-y-hidden"
              }
            >
              <img
                src={BiensVendus}
                className={"object-scale-down h-full drop-shadow-xl"}
                alt={"biens vendus"}
              />
            </span>
          </section>
        </div>
      </main>
    </div>
  );
}

/**
 * @name EstimationHeader
 * @description Header de la section d'estimation
 * @constructor
 */
const EstimationHeader = () => {
  useEffect(() => {
    const words = gsap.utils.toArray("h4") as HTMLElement[];
    const tl = gsap.timeline({ repeat: -1, duration: 20 });

    words.forEach((word, index) => {
      tl.from(
        word,
        {
          opacity: 0,
          y: 20,
          rotateX: -90,
        },
        "<",
      ).to(
        word,
        {
          opacity: 0,
          y: -20,
          rotateX: 90,
        },
        `<${index * 1 + 0.9}`, // délai de 1 seconde avant le prochain élément
      );
    });
  }, []);

  return (
    <header className={"h-auto"}>
      <h1 className="font-bold text-darkRedTitle text-4xl mb-5">
        Obtenez une A <br />
        estimation
      </h1>
      <h4
        style={{ lineHeight: "0" }}
        className={
          "animation text-dark-red font-bold m-0 text-4xl leading-none"
        }
      >
        Gratuite
      </h4>
      <h4
        style={{ lineHeight: "0" }}
        className={
          "animation text-dark-red font-bold m-0 text-4xl leading-none"
        }
      >
        Instantanée
      </h4>
      <h4
        style={{ lineHeight: "0" }}
        className={
          "animation text-dark-red font-bold m-0 text-4xl leading-none"
        }
      >
        Fiable
      </h4>
    </header>
  );
};

export default Estimation;
