import React from "react";
import Navbar from "../components/navbar";
import DisplayResult from "../components/Estimation-Component/DisplayResult";
import "../utils/Slider.css";
import { EstimationSection } from "../utils/Estimation.utils";
import { Outlet } from "react-router-dom";
import Footers, { QRCodeFooter } from "../utils/Footers";
import EstimationHeader, {
  EstimationEmpty,
  EstimationLoading,
  EstimationTop,
  EstimerBackgroundContent,
} from "../components/Estimation-Component/Estimation.component";
import { useEstimer } from "../hooks/Estimer.hooks";

/**
 * @name Estimer
 * @description Page d'estimation
 * @constructor
 */
function Estimer() {
  const {
    data,
    setData,
    estimationValue,
    setEstimationValue,
    PhoneFocus,
    setPhoneFocus,
    clicked,
    setClicked,
    screen,
    setScreen,
    bienVendu,
    setBienVendu,
    handleBienVendu,
  } = useEstimer();

  return (
    <div className="h-auto w-full">
      <Navbar mod={true} />
      <nav className="h-[70px]" />
      <main className={""}>
        <div className={"flex h-auto bg-light-red"}>
          <EstimationTop/>
        </div>
        <div
          className={"flex lg:flex-row flex-col-reverse h-auto bg-light-red"}
        >
          <main className="h-auto lg:w-[40vw] z-10 bg-white-bubble backdrop-blur items-center flex flex-col">
            <EstimationHeader
              setData={setData}
              setEstimationValue={setEstimationValue}
              setPhoneFocus={setPhoneFocus}
              setClicked={setClicked}
              setScreen={setScreen}
              setBienVendu={setBienVendu}
            />
            <div
              className={`h-auto w-full flex flex-col gap-20 items-center justify-center px-10`}
            >
              <EstimationSection />
            </div>
          </main>
          <aside
            className={`${
              PhoneFocus
                ? "absolute w-full h-full top-0"
                : "relative h-auto w-full lg:w-[60vw] py-5 lg:py-0 lg:pb-[12vh] lg:pt-[12vh] flex justify-center"
            }`}
          >
            <EstimerBackgroundContent />
            <div
              className={`transition-all duration-300 ease-out ${
                PhoneFocus
                  ? "h-full w-full z-30 bg-white-rgba backdrop-blur flex justify-center items-center fixed"
                  : "sticky top-[12vh] h-fit w-fit p-4 sm:px-10 py-[2vh] bg-white rounded-xl"
              }`}
            >
              {data.adresse_complete ? (
                estimationValue ? (
                  <DisplayResult
                    estimationValue={estimationValue}
                    data={data}
                    screen={screen}
                    setScreen={setScreen}
                    bienVendu={bienVendu}
                    setBienVendu={setBienVendu}
                    clicked={clicked}
                    setClicked={setClicked}
                    handleBv={handleBienVendu}
                    PhoneFocus={PhoneFocus}
                    setPhoneFocus={setPhoneFocus}
                  />
                ) : (
                  <EstimationLoading />
                )
              ) : (
                <EstimationEmpty />
              )}
            </div>
          </aside>
        </div>
      </main>
      <QRCodeFooter multiple={true} />
      <Footers />
      <div>
        <Outlet />
      </div>
    </div>
  );
}

export default Estimer;
