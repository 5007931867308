import { useState } from "react";
import { headerCardList } from "../interfaces/FAQ.interface";
import { DefaultPageTemplate } from "../utils/utils.components";

/**
 * @name PolitiqueConfidentialite
 * @description Page FAQ (si modif a faire aller dans l'interface dedier)
 * @constructor
 */
const PolitiqueConfidentialite = () => {
  const [selectSection, setSelectSection] = useState(0);
  const [isOpen, setIsOpen] = useState(
    Array(headerCardList.length).fill(false),
  );

  return (
    <DefaultPageTemplate>
      <main id={"response"} className="px-2 md:px-[20%] py-20">
        <h1 className={"font-bold text-lg"}>Politique de confidentialité</h1>
        <br />
        <span className={"flex flex-col gap-5 w-full"}>
          <h2 className={"font-bold"}>
            Éditeur du site
          </h2>
          <p>
            Préambule :<br /><br />
            En tant que responsable du traitement, Sinotech recueille et traite les données à caractère personnel des
            individus qui accèdent à son site Web www.flatway.fr (ci-après dénommé le "Site") ainsi que via les
            applications Flatway et FlatwayPro disponibles sur iOS (App Store) et Android (Google Play Store et Huawei
            App Gallery) (ci-après dénommées les "Applications"), dans le cadre de leur navigation privée ou
            professionnelle.<br /><br />
            Dans un souci de protection des données personnelles, Sinotech a mis en œuvre les mesures techniques et
            organisationnelles requises pour garantir la sécurité des données personnelles qu'elle traite.<br /><br />
            Cette page vise à informer les Utilisateurs et Partenaires sur les aspects suivants relatifs aux traitements des
            données collectées via les sites et les applications de Flatway :
          </p>
          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Leurs droits concernant leurs données à caractère personnel traitées via les sites et les applications;</p>
            </div>
          </div>
          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Les modalités de collecte et de traitement de leurs données à caractère personnel ;</p>
            </div>
          </div>
          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Les mesures mises en place pour garantir la sécurité de leurs données à caractère personnel ;</p>
            </div>
          </div>
          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Les obligations de Sinotech et des Utilisateurs.</p>
            </div>
          </div>
          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Les Utilisateurs sont tenus de prendre connaissance de la présente politique.</p>
            </div>
          </div>

        </span>


        <span>
          <div>
            <span className={"font-bold"}>
              I-&nbsp;
            </span>
            <span className={"font-bold"}>
              Définition
            </span>
            <br />
          </div>
          <br />
          <p>
            Application : désigne l’application mobile et tablette « Flatway » disponibles sur IOS (App Store) et Android
            (Google Play Store) par téléchargement gratuit.<br /><br />
            Application Pro : désigne l’application mobile et tablette « FlatwayPro » disponibles sur IOS (App Store) et
            Android (Google Play Store) par téléchargement gratuit.<br /><br />
            Société : désigne la société Sinotech, société par actions simplifiées au capital de 100 000 €, immatriculée
            au RCS de Nanterre sous le numéro 425 062 197, dont le siège social est situé au 140 b avenue Charles de
            Gaulle 92200 Neuilly sur Seine.<br /><br />
            Collaborateur : Utilisateur inscrit par un Partenaire via l’Application FlatwayPro, détenant un Compte
            Utilisateur Pro<br /><br />
            Comptes utilisateurs : désignent les Comptes utilisateur personnels et les Comptes utilisateur Pro.
            Compte utilisateur personnel : désigne l’espace personnel de l’Utilisateur à usage privé, lui permettant
            d’utiliser les Services grâce à ses Identifiants.<br /><br />
            Compte utilisateur Pro : désigne l'espace accessible depuis le Site et l’Application Pro, que tout Partenaire doit
            se créer afin d’utiliser le Service Flatway, et à partir duquel il peut notamment gérer ses rendez-vous.
            Données à caractère personnel : désignent les informations, sous quelque forme que ce soit, se rapportant
            directement ou indirectement à une personne physique identifiée ou identifiable (nom, prénom, date de
            naissance, le numéro d’adhérent, le numéro de sécurité sociale, l’adresse IP, les données de connexion… )
            telles que définie par le Règlement General sur la Protection des Données (RGPD).<br /><br />
            Partenaires : désignent les agences immobilières, mandataires, notaires ou syndic de copropriétés identifiés
            par une adresse postale unique, faisant partie ou non d’un groupe ayant souscrit à l’Application FlatwayPro
            et donc titulaire d’un Compte utilisateur Pro.<br /><br />
            Site ou Site internet : désigne le service électronique interactif édité et exploité par la société, accessible
            notamment via l’adresse www.flatway.fr, les Applications et toutes déclinaisons éventuelles sur différents
            supports.<br /><br />
            Services : désignent les services fournis par la Société sur le Site Internet et/ou les Applications et décrits à
            l'article 4 des présentes CGU, ainsi que leurs extensionssousforme d'alerte, et de fonctionnalités du terminal
            de l'Utilisateur, nécessaires à leur fonctionnement.<br /><br />
            Utilisateur : désigne toute personne physique, qui accède au Site et/ou aux Applications et qui crée le cas
            échéant un Compte Utilisateur sur lesdits Site et/ou Applications.<br /><br />
            Vendeur : désigne tout Utilisateur, disposant d’un Compte utilisateur personnel ayant déposé́et mis en ligne
            une Annonce via le Service Flatway.<br /><br />
            Visiteur : désigne toute personne physique qui navigue sur le Site et/ou l’Application, sans créer, ou se
            connecter à, un Compte Utilisateur.<br /><br />
            Applications Flatway : Flatway et FlatwayPro<br /><br />
            Conditions Générales d’Utilisation : désigne les conditions générales d’utilisation pour les Utilisateurs et les
            Partenaires.<br /><br />
            Données des Utilisateurs : Les termes "données des Utilisateurs" désignent les informations, y compris les
            données à caractère personnel, fournies à Flatway par les Utilisateurs, directement ou indirectement, lors de
            l'utilisation des sites et des applications édités par la Société. Les Utilisateurs s'engagent à fournir des
            informations précises et à jour, notamment en ce qui concerne leur identité et leurs coordonnées.<br /><br />
            Données à caractère personnel : Les données à caractère personnel font référence à touteslesinformations,
            quels que soient leur format, quisont liées directement ou indirectement à une personne physique identifiée
            ou identifiable, telles que le nom, le prénom, la date de naissance, le numéro d’adhérent, l'adresse IP, les
            données de connexion, etc.<br /><br />
            Responsable de traitement : Le terme "Responsable du traitement" fait référence à la société Sinotech qui,
            seule ou en collaboration avec d'autres entités, décide des objectifs et des modalités du traitement des
            données personnelles.<br /><br />
            Pour les utilisateurs résidant dans l'Espace économique européen (EEE), au Royaume-Uni et en Suisse : en
            raison des lois sur la protection des données et d'autres législations en vigueur dans ces régions, notamment
            le Règlement général sur la protection des données (« RGPD ») de l'Union européenne, la Société ne réalise
            pas certaines des collectes et utilisations de données décrites dans cette déclaration dans l'EEE, au RoyaumeUni ou en Suisse. Ces collectes et utilisations de donnéessont identifiées dans ce document par un astérisque.
            Dans un souci de transparence, la Société s'engage à informer, à travers la présente politique, les personnes
            physiques concernées de tous lestraitements de données les concernant, conformément aux dispositions de
            la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés dans sa version modifiée,
            ainsi que dansle respect des autrestextesfrançais et communautaires applicables, notamment le Règlement
            général sur la protection des données (RGPD) (Règlement (UE) 2016/679 du Parlement européen et du
            Conseil du 27 avril 2016).
          </p>
        </span>


        <br />
        <span>
          <div>
            <span className={"font-bold"}>
              II-&nbsp;
            </span>
            <span className={"font-bold"}>
              Dispositions générales
            </span>
            <br />
          </div>
          <br />
          <p>Les Données à caractère personnel des Utilisateurs sont protégées par la Société qui agit strictement dans le
            cadre de la réglementation relative à la protection des données personnelles comprenant les dispositions de
            la loi 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, ainsi que le règlement
            européen (UE) 2016/679 du Parlement européen et du conseil du 27 avril 2016 (ci-après « la Loi Applicable
            »).<br /><br />
            Au titre de la Loi Applicable les droits des Utilisateurs sont les suivants :<br /><br />
            Les Utilisateurs bénéficient notamment des droits d'accès, de rectification, d'effacement et de limitation
            concernant leurs données à caractère personnel traitées par la Société.<br /><br />
            De plus, pour les traitements basés sur l'intérêt légitime de la Société, chaque Utilisateur a le droit de
            s'opposer, pour des raisons propres à sa situation, au traitement de ses données à caractère personnel. Ils
            peuvent notamment s'opposer à tout moment au traitement de leurs données à caractère personnel à des
            fins de prospection.<br /><br />
            Enfin, chaque Utilisateur a le droit à la portabilité de ses données à caractère personnel fournies et traitées
            par la Société.</p>
        </span>


        <br />
        <span>
          <div>
            <span className={"font-bold"}>
              III-&nbsp;
            </span>
            <span className={"font-bold"}>
              Informations fournies par les Utilisateurs et données recueillies
            </span>
          </div>
          <div>
            <span className={"font-bold"}>
              A-&nbsp;
            </span>
            <span className={"font-bold"}>
              Données collectées par Flatway
            </span>
          </div>
          <div>
            <span style={{ textDecoration: 'underline' }}>
              1.Données collectées par Flatway
            </span>
          </div>
          <br />
          <p>
            Les données fournies par l’Utilisateur à Flatway sont les suivantes :<br /><br />
            Information de navigation sur le site : Les données de connexions (adresse IP), données de navigation(pages
            consultées, clics, etc.), données de géolocalisation (Adresse de connexion)
          </p>
          <br />
          <div>
            <span className={"font-bold"}>
              Informations du compte utilisateur :&nbsp;
            </span>
            <span>
              Nous collectons des données lorsque les utilisateurs créent ou mettent
              à jour leur compte Flatway. Cela inclut le prénom, le nom, l'adresse e-mail, le numéro de téléphone,
              l'identifiant de connexion et le mot de passe, l'adresse, la photo de profil, ainsi que les paramètres des
              utilisateurs, y compris les paramètres d'accessibilité.
            </span>
          </div>

          <br />
          <div>
            <span className={"font-bold"}>
              Informations de vérification des Partenaires :&nbsp;
            </span>
            <span>
              : Cela comprend les informations fournies lors du processus
              d'inscription pour devenir partenaire, tels que les documents confirmant le droit de travailler. Ces données
              peuvent être collectées par des prestataires de services de la Société.
            </span>
          </div>

          <br />
          <div>
            <span className={"font-bold"}>
              Information de vérification de mise en vente :&nbsp;
            </span>
            <span>
              : Lors que la mise en vente d’un bien immobilier, l’application
              Flatway demande à l’utilisateur de fournir la taxe foncière concernant le bien mis en vente.
            </span>
          </div>

          <br />
          <div>
            <span className={"font-bold"}>
              Documents et photos de vérification d'identité :&nbsp;
            </span>
            <span>
              Cette catégorie englobe les pièces d'identité officielles
              comme les permis de conduire ou les passeports (pouvant contenir des photos, des numéros d'identité, la
              date de naissance et le sexe), ainsi que les photos des utilisateurs.
            </span>
          </div>

          <br />
          <div>
            <span style={{ textDecoration: 'underline' }}>
              2. Données créées pendant l'utilisation de nos services.
            </span>
          </div>
          <br />
          <p>
            Les données crées par l’Utilisateur lors de l’Utilisation des Services Flatway sont les suivantes :
          </p>

          <br />
          <div>
            <span className={"font-bold"}>
              Données de localisation :&nbsp;
            </span>
            <span>
              Nous collectons des données de localisation à partir de l'appareil mobile des
              Partenaires et des Utilisateurs lorsque l'application Flatway est en cours d'utilisation, notamment lors de la
              rencontre pour rendez-vous de visite, localisation vérifier afin de déterminer un retard éventuel de l’une des
              parties. En effet, Flatway recueille ces données à partir d’une heure avant l’heure de rendez-vous initiale
              programmée, afin de pouvoir obtenir plus d’information sur l’horaire réel du rendez-vous et sur sa viabilité
              (en cas de retard trop important de l’une des parties).
            </span>
          </div>
          <br />
          <div>
            <span className={"font-bold"}>
              Données d'utilisation :&nbsp;
            </span>
            <span>
              : Nous collectons des informations sur l'interaction des utilisateurs avec notre
              plateforme. Cela comprend les horaires et dates d'accès, les fonctionnalités ou pages de l'application
              utilisées, le type de navigateur, les pannes de l'application et d'autres activités liées au système.
            </span>
          </div>
          <br />
          <div>
            <span className={"font-bold"}>
              Données de communication :&nbsp;
            </span>
            <span>
              nousrecueillons des données concernant les communications effectuées, dans
              Mes échanges, entre les Utilisateurs et Partenaires, cela inclut la date et l'heure.
            </span>
          </div>

          <br />
          <i>
            3. Données provenant d'autres sources. Ci-dessous, une liste non exhaustive,
            <br />
            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>-&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>les sources publiques ;</p>
              </div>
            </div>
            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>-&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Les partenaires marketing et les prestataires de services, y compris les banques dans le cadre de
                  programmes de cashback, et les revendeurs de données</p>
              </div>
            </div>
            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>-&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Les forces de l'ordre, les agents de santé publique et les autres entités gouvernementales.</p>
              </div>
            </div>
          </i>

          <br />
          <span className={"font-bold"}>
            B. Utilisation des données
          </span>
          <span>
            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>1.&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Flatway utilise des données pour proposer aux utilisateurs des informations fiables. Nous utilisons
                  également les données :</p>
              </div>
            </div>

            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>-&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Pour améliorer la sûreté et la sécurité de nos services et des utilisateurs, grâce à la vérification des
                  comptes des utilisateurs, leur identité ou leur conformité aux exigences de sécurité.</p>
              </div>
            </div>

            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>-&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Pour l'assistance client : Les données collectées sont utilisées pour fournir une assistance client,
                  notamment pour examiner et résoudre les problèmes des utilisateurs, ainsi que pour surveiller et
                  améliorer la qualité et les processus de notre assistance client.</p>
              </div>
            </div>
            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>-&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Pour la recherche et le développement : Nous utilisons les données à des fins d'analyse,
                  d'apprentissage automatique, de développement de produits, de recherche et de test. Cela nous
                  permet d'améliorer la praticité et la facilité d'utilisation de nos services, tout en renforçant leur
                  sécurité. Nous pouvons également développer de nouveaux services et fonctionnalités grâce à ces
                  données</p>
              </div>
            </div>
            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>-&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Pour permettre aux Utilisateurs et Partenaires de communiquer entre eux (notamment dansle cadre
                  de la prise de rendez-vous) ;</p>
              </div>
            </div>
            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>-&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Pour le marketing et la publicité ;</p>
              </div>
            </div>
            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>-&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Pour mieux comprendre votre projet immobilier, notamment dans le cadre de la communications
                  non promotionnelles aux Utilisateurs (données récoltées via le dépôt de cookies dédiés soumis à
                  consentement)</p>
              </div>
            </div>
            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>-&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Nous vous recommandons et vous proposons des biens immobiliers susceptibles de vous intéresser
                  par le biais de notifications et d'alertes par e-mail. </p>
              </div>
            </div>
            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>-&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Nous diffusons des annonces et des contenus publicitaires personnalisés en fonction de vos centres
                  d'intérêt en utilisant des cookies. </p>
              </div>
            </div>
            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>-&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Pour inscription à une newsletter suite à votre demande,</p>
              </div>
            </div>
            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>-&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Dans le cadre d'actions en justice : Nous utilisons les données pour examiner et traiter les
                  réclamations ou litiges liés à l'utilisation des services Flatway, pour respecter les lois,
                  réglementations, licences ou accords d'exploitation applicables, ainsi que pour répondre à une
                  procédure juridique ou à une demande émanant d'une entité gouvernementale, y compris les forces
                  de l'ordre.<br /><br />
                  Nous pouvons partager les données des utilisateurs si nous jugeons que cela est requis par la loi ou
                  la réglementation en vigueur, les licences ou les accords d'exploitation, les procédures juridiques ou
                  les demandes gouvernementales, ou lorsque la divulgation est nécessaire pour des raisons de
                  sécurité ou d'autres motifs similaires.<br /><br />
                  Cela implique le partage de données avec les représentants des autorités répressives, les
                  responsables de la santé publique, d'autres organismes gouvernementaux, ou des tiers, selon ce qui
                  est requis :<br />
                  a) pour faire respecter nos Conditions générales d'Utilisation, notre accord avec les Utilisateurs ou
                  d'autres politiques ;<br />
                  b) pour protéger les droits ou les biens de la Société ou de tiers, ainsi que garantir la sécurité de ces
                  tiers ;<br />
                  c) en cas de réclamation ou de litige concernant l'utilisation de nos services.<br /><br />
                  Cela inclut également le partage de données avec des tiers en lien avec des opérations de fusion, la
                  vente d'actifs, une fusion, une restructuration, un financement ou l'acquisition de tout ou partie de
                  notre entreprise par une autre société, ou lors des phases de négociation y afférentes.
                </p>
              </div>
            </div>

            <p>
              La société réalise les activités susmentionnées lorsqu'elles sont nécessaires pour respecter les conditions des
              accords conclus avec ses Utilisateurs, ou dans le but de servir ses intérêts légitimes, notamment :
            </p>

            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>-&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Pour surveiller et améliorer son assistance client.</p>
              </div>
            </div>
            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>-&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Pour faciliter l'amélioration de ses services et fonctionnalités existants ou le développement de
                  nouveaux services et fonctionnalités.</p>
              </div>
            </div>
            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>-&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Pour enquêter sur les réclamations et litiges liés à l'utilisation de ses services et fonctionnalités, ainsi
                  que pour gérer ces réclamations et litiges, ou lorsque ces activités sont nécessaires pour se
                  conformer aux exigences légales applicables.</p>
              </div>
            </div>

          </span>

          <span>
            <div style={{ display: 'flex', marginLeft: '30px' }}>
              <div style={{ padding: '10px' }}>
                <p>2.&nbsp;&nbsp;&nbsp;</p>
              </div>
              <div style={{ flex: '1', padding: '10px' }}>
                <p>Conservation des données</p>
              </div>
            </div>
            <br />

            <p>Nous conservons vos données pour des durées variables selon leur type et leur finalité, conformément aux
              conditions suivantes :<br />
              Flatway conserve les données des utilisateurs aussi longtemps que nécessaire aux fins énoncées
              précédemment. Les utilisateurs ont la possibilité de demander la suppression de leur compte via les
              applications et les sites Web de Flatway.<br /><br />
              Flatway conserve les données des utilisateurs aussi longtemps que requis pour les fins énoncées
              précédemment, en tenant compte du type de données, de la catégorie d'utilisateurs concernée, des objectifs
              pour lesquels elles ont été collectées et de la durée de conservation des données après la demande de
              suppression de compte, dans le cadre des finalités décrites ci-dessous.<br /><br /></p>

            <div>
              <span className={"font-bold"}>
                Compte utilisateur :&nbsp;
              </span>
              <span>
                : Pendant la durée de vie des comptes des utilisateurs, si ces données sont nécessaires
                pour fournir nos services. Par exemple : les données du compte seront conservées ;
              </span>
            </div>
            <br />
            <div>
              <span className={"font-bold"}>
                Information de vérification de mise en vente :&nbsp;
              </span>
              <span>
                Les documents ne seront ni archivés dans nos systèmes ni
                partagés avec le professionnel choisi pour la vente. Les données extraites des scans sont exclusivement
                utilisées pour établir le mandat de vente.
              </span>
            </div>
            <br />
            <div>
              <span className={"font-bold"}>
                Document et photos de vérification d'identité :&nbsp;
              </span>
              <span>
                Les informations telles que les noms et prénoms sont
                indispensables pour fournir nos services.<br />
                Les documents ne seront ni archivés dans nos systèmes ni partagés avec les professionnels.
              </span>
            </div>
            <br />
            <div>
              <span className={"font-bold"}>
                Pour les Documents et pièces comptables :&nbsp;
              </span>
              <span>
                10 ans, à titre de preuve comptable
              </span>
            </div>
            <br />
            <div>
              <span className={"font-bold"}>
                Géolocalisation :&nbsp;
              </span>
              <span>
                Utilisation en temps réel et suppression immédiate
              </span>
            </div>
            <br />
            <div>
              <span className={"font-bold"}>
                Compte Données relatives à une annonce publiée :&nbsp;
              </span>
              <span>
                2 ans dans une base active à compter de l’expiration du contrat;
                1 ans additionnels dans une base d’archive à accès restreint à des fins de preuves.
              </span>
            </div>

            <br /><br />
            <p>
              Les données collectées par un formulaire, lors de l'activation d'alertes, de la mise en favoris ou issues d'un
              compte, ainsi que les données de navigation relatives à votre projet immobilier, à la personnalisation du
              contenu, sont conservées pendant 3 ans dans une base active à partir de votre dernière activité ou contact
              (comme la modification de votre compte, votre navigation, la saisie d'un formulaire sur l'un de nos sites
              Internet et/ou applications). Elles sont ensuite conservées pendant 2 ans supplémentaires dans une base
              d'archive à accès restreint, à des fins probatoires.<br /><br />
              Les utilisateurs peuvent demander la suppression de leur compte à tout moment dans les menus de
              l'application Flatway, ou sur le site Web.<br /><br />
              Après la demande de suppression de compte, les données peuvent être conservées si nécessaire pour des
              raisons de sécurité, de sûreté, de prévention de la fraude ou de conformité aux exigences légales, ou en
              raison de problèmes associés au compte de l'utilisateur, tels qu'une réclamation ou un litige non résolu. En
              ce qui concerne les Partenaires, cela implique généralement la conservation de certaines de leurs données
              aussi longtemps que nécessaire pour les réclamations fiscales, les litiges ou les assurances réelles ou
              potentielles. Les données des utilisateurs et des destinataires de commande sont généralement supprimées
              dans les 90 jours suivant la demande de suppression, à moins que leur conservation ne soit requise pour les
              raisons mentionnées précédemment.<br /><br />
            </p>
          </span>

          <span>
            <span className={"font-bold"}>
              C. Vos droit et leurs applications :
            </span>
          </span>
          <br />
          <div>
            Conformément au RGPD et dans les conditions prévues par ce texte, vous disposez des droits suivants :
          </div>
          <br />

          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Droit d'accès : Vous avez le droit d'obtenir la confirmation que vos données sont traitées, et le cas
                échéant, d'accéder à vos données ainsi qu'aux informations concernant les circonstances du
                traitement.</p>
            </div>
          </div>

          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Droit de rectification : Vous avez le droit d'obtenir la rectification de vos données par la Société dans
                les meilleurs délais en cas d'inexactitude ou d'incomplétude.</p>
            </div>
          </div>


          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Droit à l'effacement : Dans certaines conditions, vous avez le droit d'obtenir l'effacement de vos
                données par Flatway dans les meilleurs délais.</p>
            </div>
          </div>


          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Droit à la limitation : Vous avez le droit d'obtenir la limitation du traitement de vos données par
                Flatway lorsque vous contestezleur exactitude, leur licéité, ou que vous vous opposez au traitement,
                ou encore lorsque vous souhaitez conserver ces données à des fins de preuve.</p>
            </div>
          </div>


          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Droit à la portabilité : Vous avez le droit de recevoir vos données traitées de manière automatisée et
                que vous avez fournies sur la base d'un contrat ou de votre consentement dans un format structuré,
                couramment utilisé et lisible par machine, ou d'obtenir la transmission de ces données à un autre
                responsable de traitement.</p>
            </div>
          </div>


          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Droit d'opposition : Vous avez le droit de vous opposer à tout moment au traitement de vos données
                par Flatway, sauf si la Société démontre des motifs légitimes et impérieux pour le traitement de vos
                données. Vous pouvez également vous opposer sans motif au traitement de vos données à des fins
                de prospection commerciale.</p>
            </div>
          </div>


          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Droit de ne pas faire l'objet d'une décision automatisée : Vous avez le droit de ne pas être soumis à
                une décision basée exclusivement sur un traitement automatisé et ayant des effets juridiques vous
                concernant ou vous affectant de manière significative, sauf si ce traitement est nécessaire à la
                conclusion ou à l'exécution d'un contrat, ou autorisé par la loi.</p>
            </div>
          </div>


          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Droit de porter réclamation : Vous avez le droit de déposer une réclamation auprès de l'autorité de
                contrôle compétente, notamment la CNIL.</p>
            </div>
          </div>


          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>-&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Directives en cas de décès : Vous avez le droit de formuler des directives concernant le traitement
                de vos données après votre décès.</p>
            </div>
          </div>

          <p>
            Pour exercer vos droits, il vous suffit de nous contacter via la rubrique « Nous contacter »<br />
            Vous pouvez également nous adresser un simple courrier à l’attention de notre Délégué à la Protection des
            Données.
          </p>
        </span>


        <br />
        <span>
          <span className={"font-bold"}>
            D. Cookies et technologies tierces :
          </span>
          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>&nbsp;&nbsp;&nbsp;1.</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Cookies sur le Site et les Application Flatway</p>
            </div>
          </div>
          <p>
            <br />
            En visitant le Site Internet et/ou les Applications, des données peuvent être enregistrées dans des fichiers
            appelés « cookies » sur l'ordinateur, la tablette ou le téléphone mobile de l'Utilisateur. Cette page vise à
            expliquer ce qu'est un cookie, son utilité et comment il peut être configuré.<br /><br />
            Flatway utilise des cookies et d'autres technologies d'identification surses applications, sites Web, e-mails et
            annonces en ligne, conformément aux objectifs énoncés dans cette déclaration ainsi que dans la Déclaration
            de Flatway relative aux cookies.<br /><br />
            Un cookie est un fichier texte pouvant être enregistré, selon le choix de l'Utilisateur, dans un espace dédié
            du disque dur de son terminal, lors de la consultation d'un service en ligne via le logiciel de navigation de
            l'Utilisateur.<br /><br />
            Le fichier cookie permet à son émetteur d'identifier le terminal dans lequel il est enregistré pendant la durée
            de validité du cookie.<br /><br />
          </p>

          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>&nbsp;&nbsp;&nbsp;2.&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Utilisation des cookies sur le Site Internet et les Applications</p>
            </div>
          </div>


          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>&nbsp;&nbsp;&nbsp;-&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>L'identification des utilisateurs ;</p>
            </div>
          </div>

          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>&nbsp;&nbsp;&nbsp;-&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>La sauvegarde des préférences et des paramètres utilisateur ;</p>
            </div>
          </div>

          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>&nbsp;&nbsp;&nbsp;-&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>L'évaluation de la popularité du contenu ;</p>
            </div>
          </div>

          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>&nbsp;&nbsp;&nbsp;-&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>L'amélioration et la mesure de l'efficacité des campagnes publicitaires ;</p>
            </div>
          </div>

          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>&nbsp;&nbsp;&nbsp;-&nbsp;</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>L'analyse des tendances et du trafic des sites Web, ainsi que la compréhension générale des
                comportements en ligne et des centres d'intérêt des personnes qui interagissent avec nos services.</p>
            </div>
          </div>

          <br />
          <p>
            Nous pouvons également autoriser des tiers à fournir des services de mesure d'audience et d'analyse en
            notre nom, à diffuser des annonces sur Internet pour notre compte ou pour le compte d'autres sociétés
            concernant nos produits et services dans nos applications, et à suivre et rendre compte des performances de
            ces annonces. Cessociétés peuvent utiliser des cookies, des pixels espions, des kits de développement logiciel
            et d'autres technologies pour identifier les appareils utilisés par les visiteurs pour accéder à notre site Web
            ainsi qu'à d'autres sites Web et services en ligne.
          </p>
          <br />
          <p className={"font-bold"}>
            Pour en savoir plus sur l'utilisation des cookies et autres technologies décrites dans cette section, consultez
            notre Déclaration relative aux cookies.
          </p>


        </span>

      </main >
    </DefaultPageTemplate >
  );
}

export default PolitiqueConfidentialite;
