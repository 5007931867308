import React, { useState } from "react";
import Navbar from "./navigation";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { SectionIdEnum } from "./types/section-id";
import Logo from "../../assets/appIcon.png";
import Footers from "../../utils/Footers";
import useDocumentTitle from "../../hooks/Title.hooks";

/**
 * @name MainLayout
 * @description Layout principal de la page d'accueil
 * @param children
 * @constructor
 */
function MainLayout({ children }: { children: any }) {
  const [isOpen, setIsOpen] = useState(false);

  useDocumentTitle("Flatway");
  return (
    <div className={"relative h-auto"}>
      <header className="sticky z-20 w-full shadow h-[70px] flex items-center justify-between top-0 bg-white px-2">
        <div className="flex items-center">
          <AnchorLink href={`#${SectionIdEnum.decouvrir}`} offset="70px">
            <img src={Logo} alt="logo" className="h-[50px] ml-[30px]" />
          </AnchorLink>
        </div>
        <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      </header>
      <div>
        <main className={"flex flex-col items-center relative w-full"}>
          {children}
        </main>
        <Footers />
      </div>
    </div>
  );
}

export default MainLayout;
