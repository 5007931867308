import React, { Suspense, lazy, useCallback, useEffect, useState } from "react";
import { EstimationResultAPI } from "../../API/Estimation.API";
import { PropertyData } from "../../interfaces/Annonce.interface";
import { EstimationDataC } from "../../interfaces/Estimer.interface";
const LazyEsti = lazy(() => import("./DisplayEstiAnnonce"));

/**
 * @name EstimationComponent
 * @description Composant d'estimation de la page d'annonce
 * @param propData
 * @param property_id
 * @constructor
 */
export const EstimationComponent = ({
  propData,
  property_id,
}: {
  propData: PropertyData;
  property_id: string;
}) => {
  const [estimationInfo, setEstimationInfo] = useState<EstimationDataC>();
  const defaultEstiInfo: EstimationDataC = {
    min: 0,
    max: 0,
    avg: 0,
    type_local: "",
    indice: 0,
  };

  const handleEstimation = useCallback(async () => {
    if (property_id) {
      const url = `https://${process.env.REACT_APP_API_URL}/api/dvf/eC?property_id=${property_id}`;
      const result = await EstimationResultAPI(url);
      if (result !== undefined) setEstimationInfo(result.estimation);
    }
  }, []);

  useEffect(() => {
    handleEstimation();
  }, []);

  return (
    <Suspense
      fallback={
        <div className={"h-[30%] w-full flex flex-col"}>
          <div
            className={
              "w-full h-full border-2 border-dark-red rounded flex justify-center"
            }
          >
            <div
              className={
                "gap-3 animate-pulse flex flex-col h-full w-full items-center p-3"
              }
            >
              <div className={"rounded-lg h-3 bg-slate-200 w-[40%] "}></div>
              <div className="rounded-full bg-slate-200 h-[50px] w-[50px]"></div>
              <div className="grid grid-cols-3 gap-4 w-[20%] h-[30px]">
                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
              </div>
              <div className="grid grid-cols-3 gap-4 w-[40%] h-[30px]">
                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
              </div>
            </div>
          </div>
        </div>
      }
    >
      <LazyEsti
        estimationValue={estimationInfo || defaultEstiInfo}
        propData={propData}
      />
    </Suspense>
  );
};
