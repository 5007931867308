import {
  DisplayEstimationList,
  extractParams,
  handleEstimationChange,
} from "../../utils/Estimation.utils";
import { IoClose } from "react-icons/io5";
import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { estimationInfo } from "../Home-component/Estimation";
import { useNavigate } from "react-router-dom";
import { EstimationData } from "../../interfaces/Estimer.interface";
import { BienImmo } from "../../interfaces/interface";
import blob from "../../assets/geometry/blob.svg";
import blob2 from "../../assets/geometry/blob2.svg";
import blob3 from "../../assets/geometry/blob3.svg";
import Logo from "../../assets/appIcon.png";
import useDocumentTitle from "../../hooks/Title.hooks";


/**
 * @name EstimationHeader
 * @description Header de la section d'estimation de la page Flatway (animation + input)
 * @param setData
 * @param setEstimationValue
 * @param setPhoneFocus
 * @param setClicked
 * @param setScreen
 * @param setBienVendu
 * @constructor
 */
const EstimationHeader = ({
  setData,
  setEstimationValue,
  setPhoneFocus,
  setClicked,
  setScreen,
  setBienVendu,
}: {
  setData: (data: estimationInfo) => void;
  setEstimationValue: (data: EstimationData | null) => void;
  setPhoneFocus: (data: boolean) => void;
  setClicked: (data: boolean) => void;
  setScreen: (data: number) => void;
  setBienVendu: (data: BienImmo[] | null) => void;
}) => {
  const searchBarRef = useRef<HTMLInputElement>(null);
  const [estimationFocus, setEstimationFocus] = useState(false);
  const [estimationInput, setEstimationInput] = useState("");
  const [estimationList, setEstimationList] = useState<estimationInfo[]>([]);
  const [selectAddress, setSelectAddress] = useState<estimationInfo>();
  const navigate = useNavigate();

  const handleEstimationClick = async () => {
    const newData = extractParams();
    setData(newData);
    setEstimationValue(null);
    setPhoneFocus(false);
    setClicked(false);
    setScreen(0);
    setBienVendu(null);
    setEstimationFocus(false);
    setEstimationInput("");
    setEstimationList([]);
    setSelectAddress(undefined);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      searchBarRef.current &&
      !searchBarRef.current.contains(event.target as Node)
    ) {
      setEstimationFocus(false);
    }
  };

  function transformAddress(address: string): string {
    const [streetPart, cityPart] = address.split(',');

    const streetMatch = streetPart.trim().match(/^(\d+)\s+(.+)$/);
    if (!streetMatch) {
        throw new Error("Invalid street format");
    }
    const streetNumber = streetMatch[1];
    const streetName = streetMatch[2].replace(/\s+/g, '-');

    const cityMatch = cityPart.trim().match(/^(\d{5})\s+(.+)$/);
    if (!cityMatch) {
        throw new Error("Invalid city format");
    }
    const postalCode = cityMatch[1];
    const cityName = cityMatch[2].replace(/\s+/g, '-');

    let result = `/${postalCode.substring(0, 2)}/${cityName}-${postalCode}/${streetName}/${streetNumber}/`;
    console.log(result)
    result = result.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, "-");
    console.log(result)
    return result;
  }

  function reverseTransformAddress(url: string): string {
    // Step 1: Match the URL format
    const regex = /^\/(\d{2})\/([a-zA-Z0-9-]+)-(\d{5})\/([a-zA-Z0-9-]+)\/(\d+)\/$/;
    const match = url.match(regex);
  
    if (!match) {
        throw new Error("Invalid URL format");
    }
  
    // Step 2: Extract components from the matched regex groups
    const postalCodePrefix = match[1];  // "72" from /72/
    const cityName = match[2].replace(/-/g, ' '); // Replace dashes back to spaces
    const postalCode = match[3];  // "72364" from -72364
    const streetName = match[4].replace(/-/g, ' '); // Replace dashes back to spaces
    const streetNumber = match[5]; // "45" from /45/
  
    // Step 3: Combine them into the original format
    const result = `${streetNumber} ${streetName}, ${postalCode} ${cityName}`.replaceAll(' ', '-');
    return result;
  }

  function extractParams(): estimationInfo {
    let url = window.location.toString().split('/estimation')
    let adr = reverseTransformAddress(url[1]);
    const adress_complete = adr || "";
    return {
      adresse_complete: adress_complete
    };
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const words = gsap.utils.toArray("h4") as HTMLElement[];
    const tl = gsap.timeline({ repeat: -1, duration: 20 }); // Start the timeline

    words.forEach((word, index) => {
      tl.from(
        word,
        {
          opacity: 0,
          y: 20,
          rotateX: -90,
        },
        "<",
      ).to(
        word,
        {
          opacity: 0,
          y: -20,
          rotateX: 90,
        },
        `<${index + 0.9}`,
      );
    });
  }, []);

  const info = extractParams()
  const adr = info.adresse_complete.replaceAll('-', ' ').split(', ')[0];
  const ville = info.adresse_complete.replaceAll('-', ' ').split(', ')[1];

  useDocumentTitle(adr + ", " + ville);

  return (
    <div className="w-full lg:h-[80vh] flex justify-center p-2 py-5 lg:py-5 sm:px-[20%] flex-col">
      <header>
        <h1 className="font-bold text-dark-red text-4xl">
          {adr}
        </h1>
        <h1 className="font-bold text-4xl mb-5">
          {ville}
        </h1>
        <h2 className="font-bold text-4xl mb-5">
          Obtenez une <br />
          estimation
        </h2>
        <h4
          style={{ lineHeight: "0" }}
          className={
            "animation text-dark-red font-bold m-0 text-4xl leading-none"
          }
        >
          Gratuite
        </h4>
        <h4
          style={{ lineHeight: "0" }}
          className={
            "animation text-dark-red font-bold m-0 text-4xl leading-none"
          }
        >
          Instantanée
        </h4>
        <h4
          style={{ lineHeight: "0" }}
          className={
            "animation text-dark-red font-bold m-0 text-4xl leading-none"
          }
        >
          Fiable
        </h4>
      </header>
      <div className="flex flex-col w-full gap-2 justify-evenly mt-10">
        <div ref={searchBarRef} className={`w-full flex flex-col`}>
          <span
            className={`transition-all relative duration-300 rounded-lg bg-white p-[.5em] border flex flex-row min-h-[49px] h-[49px] w-full ${
              estimationFocus ? "border-[#b64c4c]" : "border-[#BABABA]"
            }`}
          >
            <span className={"w-full flex justify-between h-full items-center"}>
              <input
                onFocus={() => setEstimationFocus(true)}
                value={estimationInput}
                onChange={(e) =>
                  handleEstimationChange(
                    e.target.value,
                    setEstimationInput,
                    setEstimationList,
                  )
                }
                placeholder="Ex : 171 avenue Jean Jaurès, 75019 Paris"
                className={`focus:outline-none sm:mr-2 w-full`}
              />
              <button
                className={`${estimationInput.length > 0 ? "block" : "hidden"}`}
                onClick={() => {
                  setEstimationInput("");
                  setEstimationList([]);
                }}
              >
                <IoClose className={"text-[#737373]"} />
              </button>
            </span>
            <DisplayEstimationList
              estimationList={estimationList}
              estimationFocus={estimationFocus}
              setEstimationFocus={setEstimationFocus}
              setEstimationInput={setEstimationInput}
              setSelectAddress={setSelectAddress}
            />
          </span>
        </div>
        <button
          onClick={() => {
            if (selectAddress?.adresse_complete) {
              let adr = transformAddress(selectAddress?.adresse_complete)
              navigate(
                `/estimation${adr}`,
              );
              handleEstimationClick();
            } else {
              navigate("/estimation");
            }
          }}
          className={`transition-all w-fit duration-300 text-white bg-dark-red font-medium my-3.5 px-4 py-2 rounded-lg`}
        >
          Estimer
        </button>
      </div>
    </div>
  );
};

/**
 * @name EstimerBackgroundContent
 * @description Diffentes formes géométriques en arrière-plan de la section d'estimation
 * @constructor
 */
export const EstimerBackgroundContent = () => {
  return (
    <>
      <img
        alt={"blob"}
        src={blob}
        className={"absolute h-[30vh] -left-[7vw] drop-shadow-lg"}
      />
      <img
        alt={"blob2"}
        src={blob2}
        className={"absolute h-[30vh] right-0 bottom-[50vh] drop-shadow-lg"}
      />
      <img
        alt={"blob3"}
        src={blob3}
        className={"absolute h-[30vh] left-[8vw] top-[100vh] drop-shadow-lg"}
      />
      <div
        className={
          "h-[20vh] w-[20vh] bg-dark-red animate-blob absolute right-0"
        }
      />
      <div
        className={
          "h-[20vh] w-[20vh] bg-dark-red animate-blob2 right-3/4 absolute bottom-0"
        }
      />
    </>
  );
};

/**
 * @name EstimationLoading
 * @description Composant de chargement de la page d'estimation
 * @constructor
 */
export const EstimationLoading = () => {
  return (
    <div className={"h-full  flex justify-center items-center"}>
      <div
        className={
          "w-[272px] h-[552.5px] bg-[url('./assets/phone_esti.png')] bg-contain bg-no-repeat rounded flex flex-col items-center justify-center"
        }
      >
        <img
          src={Logo}
          alt={"Loading logo"}
          className={"object-scale-down h-[10vh]"}
        />
        <svg
          width="20"
          height="20"
          fill="currentColor"
          className="mr-2 animate-spin"
          viewBox="0 0 1792 1792"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
        </svg>
        <p>Chargement en cours</p>
      </div>
    </div>
  );
};

/**
 * @name EstimationEmpty
 * @description Composant d'estimation vide
 * @constructor
 */
export const EstimationEmpty = () => {
  return (
    <div className={"h-full  flex justify-center items-center"}>
      <div
        className={
          "w-[272px] h-[552.5px] bg-no-rep eat bg-[url('./assets/phone_esti.png')] bg-contain rounded flex flex-col items-center justify-center"
        }
      >
        <img
          src={Logo}
          alt={"Loading logo"}
          className={"object-scale-down h-[10vh]"}
        />
      </div>
    </div>
  );
};

/**
 * @name EstimationTop
 * @description Header de la section d'estimation de la page Flatway (animation + input)
 */
export const EstimationTop = () => {

  function reverseTransformAddress(url: string): string {
    // Step 1: Match the URL format
    const regex = /^\/(\d{2})\/([a-zA-Z0-9-]+)-(\d{5})\/([a-zA-Z0-9-]+)\/(\d+)\/$/;
    const match = url.match(regex);
  
    if (!match) {
        throw new Error("Invalid URL format");
    }
  
    // Step 2: Extract components from the matched regex groups
    const postalCodePrefix = match[1];  // "72" from /72/
    const cityName = match[2].replace(/-/g, ' '); // Replace dashes back to spaces
    const postalCode = match[3];  // "72364" from -72364
    const streetName = match[4].replace(/-/g, ' '); // Replace dashes back to spaces
    const streetNumber = match[5]; // "45" from /45/
  
    // Step 3: Combine them into the original format
    const result = `${streetNumber} ${streetName}, ${postalCode} ${cityName}`.replaceAll(' ', '-');
    return result;
  }

  function extractParams(): estimationInfo {
    let url = window.location.toString().split('/estimation')
    let adr = reverseTransformAddress(url[1]);
    const adress_complete = adr || "";
    return {
      adresse_complete: adress_complete
    };
  }

  const info = extractParams()
  const adr = info.adresse_complete.replaceAll('-', ' ').split(', ')[0] + ", ";
  const ville = info.adresse_complete.replaceAll('-', ' ').split(', ')[1];

  return (
    <div className="w-full lg:h-[10vh] flex justify-center items-center p-2 py-5 lg:py-5 sm:px-[20%] flex-col bg-white z-10">
        <h3>Estimation du :</h3>
        <h1 className="font-bold text-dark-red text-3xl">
          {adr}<span style={{ color: 'black' }}>{ville}</span>
        </h1>
    </div>
  );
};

export default EstimationHeader;
