import React from "react";
import dlApple from "../assets/DownloadApple.svg";
import dlGoogle from "../assets/DownloadAndroid.png";
import {
  AiFillFacebook,
  AiOutlineTwitter,
  AiFillLinkedin,
} from "react-icons/ai";
import Logo from "../assets/appIcon.png";
import { useNavigate } from "react-router-dom";
import { SectionIdEnum } from "../components/Home-component/types/section-id";
import qrCode from "../assets/QRCodeFlat.png";
import qrCodePro from "../assets/QRCodeFlatPro.png";
import qrCodeApple from "../assets/qr-code.png";

/**
 * @name QRCodeFooter
 * @description Partie qrCode / telechargement de l'application
 * @param multiple
 * @constructor
 */
export const QRCodeFooter = ({ multiple }: { multiple: boolean }) => {
  return (
    <div
      className={
        "hidden lg:block h-auto py-10 flex items-center justify-center w-full px-2 xl:px-[19vw]"
      }
      id={SectionIdEnum.telecharger}
      key={SectionIdEnum.telecharger}
    >
      <div className={"flex items-center w-full flex-col gap-5"}>
        <h1 className={"font-bold text-lg 2xl:text-3xl w-full sm:w-full"}>
          Retrouvez toutes les fonctionnalités dans{" "}
          {multiple ? "les applications" : "notre application"}
        </h1>
        <div className={"flex flex-col sm:flex-row w-full sm:w-full gap-5"}>
          <section
            className={
              "sm:w-1/2 h-auto bg-slate-50 gap-4 items-center p-4 flex"
            }
          >
            <aside className={"h-[15vh]"}>
              <img
                src={qrCode}
                alt={"qrCode user"}
                className={"object-scale-down h-full"}
              />
            </aside>
            <p className={"font-bold"}>
              Téléchargez l’application
              <br />
              pour vendre et acheter
              <br />
              <br />
              <span className={"font-normal"}>
                Téléchargez maintenant Flatway Android
              </span>
            </p>
          </section>
          {multiple && (
            <section
              className={
                "lg:w-1/2 h-auto bg-slate-50 gap-4 items-center p-4 flex"
              }
            >
              <aside className={"h-[15vh]"}>
                <img
                  src={qrCodePro}
                  alt={"qrCodePro user"}
                  className={"object-scale-down h-full"}
                />
              </aside>
              <p className={"font-bold"}>
              <br />
                Téléchargez l’application
                <br />
                pour les professionnels
                <br />
                <br />
                <span className={"font-normal"}>
                Téléchargez maintenant{" "}
                  <span className={"mr-[2px]"}>Flatway</span>Pro Android
                </span>
              </p>
            </section>
          )}
        </div>
        <div className={"flex flex-col sm:flex-row w-full sm:w-full gap-5"}>
          <section
            className={
              "sm:w-1/2 h-auto bg-slate-50 gap-4 items-center p-4 flex"
            }
          >
            <aside className={"h-[15vh]"}>
              <img
                src={qrCodeApple}
                alt={"qrCodeApple user"}
                className={"object-scale-down h-full"}
              />
            </aside>
            <p className={"font-bold"}>
              <span className={"text-dark-red"}>
                Bientot disponible
              </span>
              <br />
              Téléchargez l’application
              <br />
              pour vendre et acheter
              <br />
              <br />
              <span className={"font-normal"}>
                Scannez pour télécharger Flatway IOS
              </span>
            </p>
          </section>
          {multiple && (
            <section
              className={
                "lg:w-1/2 h-auto bg-slate-50 gap-4 items-center p-4 flex"
              }
            >
              <aside className={"h-[15vh]"}>
                <img
                  src={qrCodeApple}
                  alt={"qrCodeApple user"}
                  className={"object-scale-down h-full"}
                />
              </aside>
              <p className={"font-bold"}>
              <span className={"text-dark-red"}>
                Bientot disponible
              </span>
              <br />
                Téléchargez l’application
                <br />
                pour les professionnels
                <br />
                <br />
                <span className={"font-normal"}>
                  Scannez pour télécharger{" "}
                  <span className={"mr-[2px]"}>Flatway</span>Pro IOS
                </span>
              </p>
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * @name Footers
 * @description Footer de l'application
 * @constructor
 */
function Footers() {
  const navigate = useNavigate();
  return (
    <footer className="hidden lg:block sm:h-[46vh] flex justify-center w-full bg-dark-red">

      <div className={"h-auto w-full lg:w-[70vw]"}>
        <div
          className={
            "sm:h-[30vh] sm:py-0 py-10 sm:px-10 px-2 gap-10 sm:gap-0 flex sm:flex-row flex-col justify-between items-center"
          }
          style={{ boxShadow: "0 4px 2px -2px rgba(255,255,255,0.7)" }}
        >
          <div
            className={
              "flex md:flex-row w-full flex-col items-start gap-[10vw]"
            }
          >
            <span>
              <h1 className={"font-semibold text-lg text-white"}>Flatway</h1>
              <ul className={"flex flex-col"}>
                <li>
                  <button
                    className={
                      "text-slate-100 hover:text-slate-500 font-semibold transition-all duration-300"
                    }
                    onClick={() => {
                      window.scroll(0, 0);
                      navigate("/flatway");
                    }}
                  >
                    Flatway
                  </button>
                </li>
                <li>
                  <button
                    className={
                      "text-slate-100 hover:text-slate-500 font-semibold transition-all duration-300"
                    }
                    onClick={() => {
                      window.scroll(0, 0);
                      navigate("/flatwaypro");
                    }}
                  >
                    FlatwayPro
                  </button>
                </li>
                <li>
                  <button
                    className={
                      "text-slate-100 hover:text-slate-500 font-semibold w-fit transition-all duration-300"
                    }
                    onClick={() => {
                      window.scroll(0, 0);
                      navigate("/a-propos");
                    }}
                  >
                    À propos
                  </button>
                </li>
                <li>
                  <button
                    className={
                      "text-slate-100 hover:text-slate-500 text-start font-semibold transition-all duration-300"
                    }
                    onClick={() => {
                      window.scroll(0, 0);
                      navigate("/presse");
                    }}
                  >
                    Presse
                  </button>
                </li>
              </ul>
            </span>
            <div>
              <h1 className={"font-semibold text-lg text-white"}>Services</h1>
              <ul className={"flex flex-col items-start"}>
                <button
                  className={
                    "text-slate-100 hover:text-slate-500 font-semibold transition-all duration-300"
                  }
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/consultation?city=Paris&zipcode=75000&tb=1");
                  }}
                >
                  Consulter
                </button>
                <button
                  className={
                    "text-slate-100 hover:text-slate-500 font-semibold transition-all duration-300"
                  }
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/estimation");
                  }}
                >
                  Estimer
                </button>
                <button
                  className={
                    "text-slate-100 hover:text-slate-500 font-semibold transition-all duration-300"
                  }
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/vente");
                  }}
                >
                  Vendre
                </button>
              </ul>
            </div>
            <span>
              <h1 className={"font-semibold text-lg text-white"}>Aide</h1>
              <ul className={"flex text-start flex-col"}>
                <li>
                  <button
                    className={
                      "text-slate-100 hover:text-slate-500 font-semibold transition-all duration-300"
                    }
                    onClick={() => {
                      window.scroll(0, 0);
                      navigate("/faq");
                    }}
                  >
                    FAQ
                  </button>
                </li>
                <li>
                  <button
                    className={
                      "text-slate-100 hover:text-slate-500 font-semibold transition-all duration-300"
                    }
                    onClick={() => {
                      window.scroll(0, 0);
                      navigate("/contact");
                    }}
                  >
                    Nous contacter
                  </button>
                </li>
              </ul>
            </span>
          </div>
          <section className={"flex items-center gap-5"}>
            <img src={Logo} alt={"Logo"} className={"h-[50px] rounded-lg"} />
            <p className={"font-semibold text-white text-lg"}>Flatway</p>
          </section>
        </div>
        <section
          className={"h-auto sm:h-[10vh] sm:py-0 py-10 px-10"}
          style={{ boxShadow: "0 4px 2px -2px rgba(255,255,255,0.7)" }}
        >
          <span className={"flex justify-between items-center h-full"}>
            <ul className={"flex items-center"}>
              <li>
                <a href={"https://www.facebook.com"} aria-label={"Facebook"}>
                  <AiFillFacebook
                    className={
                      "text-3xl text-slate-100 transition-all duration-300 hover:text-[#4267B2]"
                    }
                  />
                </a>
              </li>
              <li>
                <a href={"https://www.linkedin.com"} aria-label={"LinkedIn"}>
                  <AiFillLinkedin
                    className={
                      "text-3xl text-slate-100 transition-all duration-300 hover:text-[#0077B5]"
                    }
                  />
                </a>
              </li>
              <li>
                <a href={"https://www.twitter.com"} aria-label={"Twitter"}>
                  <AiOutlineTwitter
                    className={
                      "text-3xl text-slate-100 transition-all duration-300 hover:text-[#1DA1F2]"
                    }
                  />
                </a>
              </li>
            </ul>
            <ul className={"flex items-center"}>
              <li>
                <a href={"https://apps.apple.com/fr/app/flatwaypro/id6692629149"} aria-label={"Apple"}>
                  <img className={"w-[100px]"} src={dlApple} alt={"Apple"} />
                </a>
              </li>
              <li>
                <a href={"https://play.google.com/store/search?q=flatway&c=apps&gl=FR"} aria-label={"Google"}>
                  <img className={"w-[100px]"} src={dlGoogle} alt={"Google"} />
                </a>
              </li>
            </ul>
          </span>
        </section>
        <section className={"h-auto sm:h-[5vh]"}>
          <span
            className={
              "flex flex-wrap justify-between items-center h-full text-[0.6rem] sm:text-sm text-slate-100"
            }
          >
            <p>Flatway © 2024</p>
            <ul className={"flex flex-wrap gap-5"}>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  //onClick={() => navigate("/cgu")}
                  href={require("../assets/pdf/CGU.pdf")}
                  className={"text-[2wv]"}
                >
                  Conditions générales d’utilisation
                </a>
              </li>
              <li>
                <p className={"text-[2wv] cursor-pointer"}>
                  Gestion des cookies
                </p>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href={require("../assets/pdf/Regles Referencement-4-24.pdf")}
                  className={"text-[2wv]"}
                >
                  Règles de référencement
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href={require("../assets/pdf/Politique de confidentialite.pdf")}
                  className={"text-[2wv]"}
                >
                  Politique de confidentialité
                </a>
              </li>
            </ul>
          </span>
        </section>
      </div>
    </footer>
  );
}

export default Footers;
