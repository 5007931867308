import { useState } from "react";
import { headerCardList } from "../interfaces/FAQ.interface";
import { DefaultPageTemplate } from "../utils/utils.components";

/**
 * @name ReglesReferencement
 * @description Page FAQ (si modif a faire aller dans l'interface dedier)
 * @constructor
 */
const ReglesReferencement = () => {
  const [selectSection, setSelectSection] = useState(0);
  const [isOpen, setIsOpen] = useState(
    Array(headerCardList.length).fill(false),
  );

  return (
    <DefaultPageTemplate>
      <main id={"response"} className="px-2 md:px-[20%] py-20">
        <h2 className={"font-bold"} style={{ color: '#920000' }}>
          RÈGLES DE RÉFÉRENCEMENT
        </h2>

        <br />
        <span>
          <p className={"font-bold"} style={{ color: '#920000' }}>
            Modalités d’affichage des professionnels immobiliers :
          </p>
          <br />
          <p>
            Flatway permet aux vendeurs de rechercher un professionnel qui exerce dans le secteur de leurs biens à vendre.
            La liste des professionnels actifs dans le secteur est affichée comme suit :
          </p>

          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>&nbsp;&nbsp;&nbsp;1.</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Les professionnels ayant au moins un bien en vente sur Flatway. Cette catégorie est triée selon le nombre de ventes en
                ligne, par ordre décroissant</p>
            </div>
          </div>

          <div style={{ display: 'flex', marginLeft: '30px' }}>
            <div style={{ padding: '10px' }}>
              <p>&nbsp;&nbsp;&nbsp;2.</p>
            </div>
            <div style={{ flex: '1', padding: '10px' }}>
              <p>Les professionnels n’ayant aucun bien en vente sur Flatway, classés aléatoirement</p>
            </div>
          </div>

          <br />

          <span>
            <div>
              <u>
                A noter
              </u>
              <span>
                &nbsp;: Flatway ne joue aucun rôle actif dans ce classement, Il reflète simplement les choix des vendeurs.
              </span>
              <br />
            </div>
            <br />
            <p className={""}>
              Les vendeurs sont également informés qu’ils sont libres de choisir un professionnel dans la liste affichée ou, à défaut, de demander
              au professionnel souhaité de s’inscrire sur Flatway avant de mettre en vente leurs biens.
              Flatway ne détient aucune participation capitalistique avec les professionnels partenaires, aucune rémunération d’aucune sorte n’a
              d’incidence sur l’accès des professionnels à l’application Flatway ; aucune option payante n’est proposée par Flatway aux
              professionnels pour prioriser l’ordre d’affichage dans la liste
            </p>
          </span>
        </span>


        <br />
        <span>
          <p className={"font-bold"} style={{ color: '#920000' }}>
            Classement des annonces
          </p>
          <br />
          <p>
            Les annonces sur Flatway sont classées par <span className={"font-bold"}>date de publication</span>, les annonces les plus récentes étant affichées en premier.
            Afin de ne pas favoriser une annonce plus qu’une autre, les annonces publiées à la même date sont aléatoirement affichées.
            Aucune option payante n’est proposée par Flatway aux professionnels pour prioriser l’ordre d’affichage des annonces.
            L’utilisateur a la possibilité ensuite de personnaliser son tri par prix croissant/décroissant, surface croissante/décroissante et par
            date.
          </p>
        </span>

        <br />
        <span>
          <p className={"font-bold"} style={{ color: '#920000' }}>
            Informations sur nos services
          </p>
          <br />
          <p>
            Notre site web et nos applications mobiles offrent des services de mise en relation Vendeurs - Professionnels – Acheteurs ; Flatway
            ne vend pas de l’immobilier.<br /><br />
            Flatway permet aux professionnels de renseigner librement un ou deux types de barèmes d’honoraires, un barème d’honoraires
            standards et un barème d’honoraires à taux réduits à destination des vendeurs désireux d’assurer eux-mêmes les visites en lieu et
            place du professionnel.<br /><br />
            Les professionnels sont libres de proposer aux vendeurs un des deux barèmes ou les deux barèmes à la fois.<br /><br />
            Dans les deux cas et jusqu’à la signature chez le notaire, le professionnel est l’interlocuteur de l’acheteur et du vendeur.<br /><br />
            Tous nos services sont gratuits pour les utilisateurs, vendeurs et acheteurs ; le professionnel verse à Flatway des frais de services
            seulement si la vente est signée chez le notaire.
            </p>
        </span>


      </main >
    </DefaultPageTemplate >
  );
}

export default ReglesReferencement;
