import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./page/Home";
import FlatWay from "./page/FlatWay";
import FlatWayPro from "./page/FlatWayPro";
import Consulter from "./page/Consulter";
import Estimer from "./page/Estimer";
import { Annonce } from "./page/Annonce";
import { Contact } from "./page/Contact";
import NoPage from "./page/NoPage";
import AuthPage from "./page/Auth";
import PrivateRoutes from "./utils/PrivateRoutes";
import NewsPage from "./page/News";
import AboutUs from "./page/AboutUs";
import { FlatwayProPhone } from "./page/FlatwayProPhone";
import FlatwayPhone from "./page/FlatwayPhone";
import Vendre from "./page/Vendre";
import Article from "./page/Article";
import FAQPage from "./page/FAQ";
import CGUPage from "./page/CGU";
import PolitiqueConfidentialite from "./page/PolitiqueConfidentialite";
import ReglesReferencement from "./page/ReglesReferencement";
import SellPage from "./page/FlatwaySub/HowToSell";
import BuyPage from "./page/FlatwaySub/HowToBuy";
import VisitAndOfferPage from "./page/FlatwaySub/VisitAndOffer";
import CreatePassword from "./page/CreatePassword";

/**
 * Main component of the application
 * @constructor
 */
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"create password"} element={<CreatePassword />} />
        <Route path="/" element={<Home />} />
        <Route path="flatway" element={<FlatWay />} />
        <Route path="comment-vendre" element={<SellPage />} />
        <Route path="comment-acheter" element={<BuyPage />} />
        <Route path="offre-et-visite" element={<VisitAndOfferPage />} />
        <Route path="flatway-phone" element={<FlatwayPhone />} />
        <Route path="flatwaypro" element={<FlatWayPro />} />
        <Route path="flatwaypro-phone" element={<FlatwayProPhone />} />
        <Route path="consultation*" element={<Consulter />} />
        <Route path="estimation*" element={<Estimer />} />
        <Route path="annonce*" element={<Annonce />} />
        <Route path="contact" element={<Contact />} />
        <Route path={"a-propos"} element={<AboutUs />} />
        <Route path={"presse"} element={<NewsPage />} />
        <Route path={"vente"} element={<Vendre />} />
        <Route path={"faq"} element={<FAQPage />} />
        <Route path={"cgu"} element={<CGUPage />} />
        <Route path={"politique-confidentialite"} element={<PolitiqueConfidentialite />} />
        <Route path={"regles-referencement"} element={<ReglesReferencement />} />
        <Route path={"/article/:id"} element={<Article />} />
        <Route path={"*"} element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
